import { useEffect, useState } from "react";
import Papa from "papaparse";
import { FaEdit, FaTrash, FaEye, FaSms } from "react-icons/fa";
import { EmailModel } from "../Components/EmailModel";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { CiVideoOn } from "react-icons/ci";
import { WhatsAppCon } from "../Components/WhatsAppCon";
import Loader from "../Components/Loader";
import { ScheduleMeeting } from "../Components/ScheduleMeeting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AllLeads = () => {
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showWhatsAppSend, setShowWhatsAppSend] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showScheduleMeeting, setShowScheduleMeeting] = useState(false);
  const [smsContent, setSmsContent] = useState(null);
  const [showSmsInput, setShowSmsInput] = useState(false);
  const [token, setToken] = useState(null);
  const [showSelectCallType, setShowSelectCallType] = useState(false);
  const [callType, setCallType] = useState("A");
  const [agent, setAgent] = useState("0505678550001");
  const [callLimit, setCallLimit] = useState(1);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    const userId = localStorage.getItem("userID");
    if (!userId) return setLeads([]);
    setLoading(true);

    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda.org/get-leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userID: userId }),
        }
      );
      setLoading(false);

      if (!response.ok) throw new Error("Failed to fetch leads");
      const data = await response.json();
      setLeads(data.Leads);
      setTotalPages(Math.ceil(data.Leads.length / entriesToShow));
      setCurrentData(data.Leads);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = filteredLeads.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );

    setCurrentData(data);
    setTotalPages(Math.ceil(filteredLeads.length / entriesToShow));
  }, [entriesToShow, page, searchTerm]);

  const handleCheckboxChange = (index) => {
    const selectedLead = currentData[index];
    setSelectedLeads((prevSelectedLeads) =>
      prevSelectedLeads.includes(selectedLead)
        ? prevSelectedLeads.filter((lead) => lead !== selectedLead)
        : [...prevSelectedLeads, selectedLead]
    );
  };

  const handleSelectAll = (isChecked) => {
    setSelectedLeads(isChecked ? [...leads] : []);
  };

  const handleShowWhatsApp = () => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to send message");
    }
    setShowWhatsAppSend(true);
  };

  const handleShowEmail = () => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to send email");
    }
    setShowEmailModel(true);
  };

  const filteredLeads = leads.filter(
    (lead) =>
      (lead.name &&
        lead.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.email &&
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.mobile && lead.mobile.includes(searchTerm))
  );

  const handleDeleteLead = async (lead) => {
    const userID = localStorage.getItem("userID");

    if (!window.confirm("Are you sure you want to delete this lead?")) {
      return;
    }

    try {
      const response = await fetch(
        `https://margda.in:7000/api/margda.org/delete-lead`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID: lead.userID,
            dataID: lead.dataID,
            euser: userID,
          }),
        }
      );

      if (!response.ok) {
        console.error(
          "Failed to delete lead. Server responded with:",
          response.status
        );
        throw new Error("Failed to delete lead");
      }

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message);
        if (lead.dataID) {
          setCurrentData((prevLeads) =>
            prevLeads.filter((pre_lead) => pre_lead.dataID != lead.dataID)
          );
          setLeads((prevLeads) =>
            prevLeads.filter((pre_lead) => pre_lead.dataID != lead.dataID)
          );
        } else if (lead.userID) {
          setCurrentData((prevLeads) =>
            prevLeads.filter((pre_lead) => pre_lead.userID != lead.userID)
          );
          setLeads((prevLeads) =>
            prevLeads.filter((pre_lead) => pre_lead.userID != lead.userID)
          );
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error occurred while deleting lead:", error.message);
      alert("Failed to delete lead. Please try again.");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const validateEmail = async (index) => {
    setLoading(true);
    const email = currentData[index].email;
    const response = await fetch(
      "https://margda.in:7000/api/email/validateemail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, validate_type: "email" }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      if (data.email) {
        currentData[index].exists = true;
      } else {
        currentData[index].notExists = true;
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (searchTerm !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchTerm]);

  const handleCall = async () => {
    const call_type = callType;
    const userID = localStorage.getItem("userID");
    const mobile = selectedLeads.map((lead) => lead.mobile);
    let number = mobile[0];
    if (number.length > 10) {
      number = "+" + number;
    }
    if (call_type === "S") {
      const response = await fetch(
        "https://margda.in:7000/api/android/push-notification/get-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userID }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        const token =
          data.Token &&
          data.Token.length &&
          data.Token[data.Token.length - 1].token;
        if (token) {
          const response = await fetch(
            "https://margda.in:7000/api/android/push-notification/send-notification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                number: number,
                text: "call",
              }),
            }
          );
          const data = await response.json();
          if (response.ok) {
            alert("Calling in progress");
          } else {
            alert("failed to call");
          }
        }
      } else if (response.status === 404) {
        alert(
          "Call feature is not available for your account, please install margda app and sign up to get call feature"
        );
      }
    } else if (call_type === "A") {
      try {
        const response = await fetch(
          "https://margda.in:7000/api/cloud_telephony/initiate_call",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              agent_number: agent,
              destination_number: number,
              userID,
              call_timeout: Number(callLimit),
            }),
          }
        );
        const data = await response.json();
        alert(data.message);
        console.log(data);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  };
  const handleSms = async (content) => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to send email");
    }
    if (selectedLeads.length > 1) {
      return alert("Select only one lead to call");
    }
    const userID = localStorage.getItem("userID");
    const response = await fetch(
      "https://margda.in:7000/api/android/push-notification/get-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      const token =
        data.Token &&
        data.Token.length &&
        data.Token[data.Token.length - 1].token;
      if (token) {
        setToken(token);
        setShowSmsInput(true);
      }
    } else if (response.status === 404) {
      alert(
        "Call feature is not available for your account, please install margda app and sign up to get call feature"
      );
    }
  };

  const handleSmsSend = async () => {
    const mobile = selectedLeads.map((lead) => lead.mobile);
    let number = mobile[0];
    if (number.length > 10) {
      number = "+" + number;
    }
    const response = await fetch(
      "https://margda.in:7000/api/android/push-notification/send-sms",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          number: number,
          text: "sms",
          content: smsContent,
        }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      alert("SMS send in progress");
    } else {
      alert("failed to send sms");
    }
  };

  return (
    <div className="flex flex-col bg-gray-200 mx-auto p-6">
      {loading ? <Loader /> : <></>}
      {showSelectCallType && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
          <div className="flex flex-col  w-[40%] items-center bg-gray-200 rounded">
            <div className="flex flex-row w-full justify-end mr-5">
              <div
                onClick={() => setShowSelectCallType(false)}
                className="my-auto font-normal border px-3 bg-gray-200 text-red-500 cursor-pointer hover:bg-red-400 hover:text-red-100 rounded"
              >
                x
              </div>
            </div>
            <div className="flex flex-col w-[80%] items-center justify-center p-4 py-9">
              <div className="flex flex-col w-full items-center justify-center p-1 py-2">
                <label
                  htmlFor="call_type"
                  className="font-bold p-1 text-base my-3"
                >
                  Select Call Type
                </label>
                <select
                  className="px-3 py-2 border w-[80%] border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                  name="call_type"
                  id="call_type"
                  value={callType}
                  onChange={(e) => setCallType(e.target.value)}
                >
                  <option value="A">Cloud Telephony</option>
                  <option value="S">Simple</option>
                </select>
              </div>
              {callType === "A" && (
                <>
                  <div className="flex flex-col w-full items-center justify-center p-1 py-2">
                    <label
                      htmlFor="call_agent"
                      className="font-bold p-1 text-base my-3"
                    >
                      Select Agent
                    </label>
                    <select
                      className="px-3 py-2 border w-[80%] border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                      name="call_agent"
                      id="call_agent"
                      value={agent}
                      onChange={(e) => setAgent(e.target.value)}
                    >
                      <option value="0505678550001">RP Singh</option>
                      <option value="0505678550007">Sanju</option>
                    </select>
                  </div>
                  <div className="flex flex-col w-full items-center justify-center p-1 py-2">
                    <label
                      htmlFor="call_limit"
                      className="font-bold p-1 text-base my-3"
                    >
                      Call Limit
                    </label>
                    <input
                      id="call_limit"
                      type="number"
                      value={callLimit}
                      onChange={(e) => setCallLimit(e.target.value)}
                      className="px-3 py-2 border w-[80%] border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                    />
                  </div>
                </>
              )}

              <div className="flex flex-row justify-center gap-4 w-full mt-5">
                <button
                  onClick={handleCall}
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
                >
                  Call
                </button>
                <button
                  onClick={() => setShowSelectCallType(false)}
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col flex-wrap items-center mb-6">
        <div className="flex justify-end flex-wrap w-full space-x-1 mb-4">
          <button
            onClick={handleShowWhatsApp}
            className="flex flex-row items-center bg-green-500  py-2 text-white px-2 rounded hover:bg-slate-600 hover:text-slate-100 font-normal font-mono text-base"
          >
            <FaWhatsapp className="mr-1" />
            WhatsApp
          </button>
          <button
            onClick={handleShowEmail}
            className="flex flex-row items-center bg-gray-500 text-white py-2  px-2 rounded hover:bg-slate-600 hover:text-slate-100 font-normal font-mono text-base"
          >
            <TfiEmail className="mr-1" />
            Email
          </button>
          <button
            onClick={() => {
              if (selectedLeads.length === 0) {
                return alert("Select a lead to send email");
              }
              if (selectedLeads.length > 1) {
                return alert("Select only one lead to call");
              }
              setShowSelectCallType(true);
            }}
            className="flex flex-row items-center bg-blue-500 text-white  py-2 px-2 rounded hover:bg-slate-600 hover:text-slate-100 font-normal font-mono text-base"
          >
            <IoIosCall className="mr-1" />
            Call
          </button>
          <button
            onClick={handleSms}
            className="flex flex-row items-center bg-orange-500 text-white  py-2 px-2 rounded hover:bg-slate-600 hover:text-slate-100 font-normal font-mono text-base"
          >
            <FaSms className="mr-1" />
            SMS
          </button>
          <button
            onClick={() => {
              if (selectedLeads.length === 0) {
                return alert("Select a lead to schedule meeting");
              }
              setShowScheduleMeeting(true);
            }}
            className="flex flex-row items-center bg-sky-500 text-white  py-2 px-2 rounded hover:bg-slate-600 hover:text-slate-100 font-normal font-mono text-base"
          >
            <CiVideoOn className="mr-1" />
            Meeting
          </button>
        </div>
      </div>
      {showSmsInput && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl -mt-32">
            <div className="flex flex-col items-center space-y-4 mb-6">
              <label
                htmlFor="message"
                className="block mb-2 text-xl font-medium text-gray-700"
              >
                Sms
              </label>
            </div>
            <div>
              <div className="my-3">
                <textarea
                  type="text"
                  name="message"
                  value={smsContent}
                  id="message"
                  onChange={(e) => setSmsContent(e.target.value)}
                  placeholder="Enter message here"
                  className="border w-1/2 p-2"
                />
              </div>
              <div>
                <button
                  onClick={handleSmsSend}
                  type="submit"
                  className="bg-blue-500 text-white px-6 py-2 mr-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                >
                  Send Sms
                </button>

                <button
                  onClick={() => setShowSmsInput(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white px-4 border-1 rounded shadow-lg">
        <div className="flex justify-between items-center my-5 w-[100%]">
          <div>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              Show Entries:
            </label>
            <select
              id="entries"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(Number(e.target.value))}
              className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="items-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
            />
          </div>
        </div>

        {currentData.length === 0 ? (
          <div className="text-center text-gray-600  p-20">No leads found</div>
        ) : (
          <div>
            <div className="rounded-lg shadow-lg max-h-[600px] overflow-y-scroll min-h-[400px]">
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-800 text-white text-center">
                    <th className="py-3 px-4">
                      <input
                        type="checkbox"
                        checked={selectedLeads.length === leads.length}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Action
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Data
                    </th>
                    {/* <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Validate Email
                </th> */}
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Logs
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.slice(0, entriesToShow).map((lead, index) => (
                    <tr
                      key={index}
                      className="border-t hover:bg-gray-100 cursor-pointer border"
                      onClick={(e) => {
                        // Prevent toggle when clicking directly on the checkbox
                        if (e.target.type !== "checkbox") {
                          handleCheckboxChange(index);
                        }
                      }}
                    >
                      <td className="py-3 px-4">
                        <input
                          type="checkbox"
                          checked={selectedLeads.includes(lead)}
                          className="cursor-pointer"
                          onChange={() => handleCheckboxChange(index)}
                          onClick={(e) => e.stopPropagation()} // Prevent row click
                        />
                      </td>
                      <td className="py-[9px] px-4 text-justify  font-sans font-normal">
                        <button
                          className="cursor-pointer text-blue-500 ml-2"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleDeleteLead(lead);
                          }}
                        >
                          <FaTrash />
                        </button>
                        <button>
                          <FaEye
                            className="cursor-pointer text-blue-500 ml-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              // Add view functionality here
                            }}
                          />
                        </button>
                        {lead.userID && (
                          <span className="text-xl text-justify ml-2 font-sans font-normal">
                            U
                          </span>
                        )}
                      </td>

                      <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                        <div className="flex flex-row items-center">
                          <div>
                            {" "}
                            <img
                              src={
                                lead.pic_url
                                  ? lead.pic_url
                                  : lead.gender === "M"
                                  ? "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                  : lead.gender === "F"
                                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmvJ14anYdkKq4d0LRhRh0a_u_Kh6DUQGHsQ&s"
                                  : "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                              }
                              alt="img"
                              className="w-20 h-20 rounded-full border-1 shadow-md mb-4"
                            />
                          </div>
                          <div>
                            {lead.name || "N/A"}
                            {<br />}
                            <span className="flex flex-row m-0 p-0 items-center">
                              <IoIosCall className="mr-1" />
                              {lead.mobile || "N/A"}
                            </span>

                            <span className="flex flex-row m-0 p-0 items-center">
                              {<FaWhatsapp className="mr-1" />}
                              {lead.whatsapp || "N/A"}
                            </span>

                            <span className="flex flex-row m-0 p-0 items-center">
                              {<TfiEmail className="mr-1" />}
                              {lead.email}
                            </span>
                            {lead.gender === "M"
                              ? "Male"
                              : lead.gender === "F"
                              ? "Female"
                              : lead.gender === null
                              ? "Not Selected"
                              : lead.gender === "O"
                              ? "Others"
                              : "N/A"}
                          </div>
                        </div>
                      </td>

                      {/* {lead.exists ? (
                    <td>
                      <div
                        className="bg-green-500 text-white px-4 py-2 rounded-lg"
                        style={{ fontSize: "17px" }}
                      >
                        email exists
                      </div>
                    </td>
                  ) : lead.notExists ? (
                    <td>
                      <div
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                        style={{ fontSize: "15px" }}
                      >
                        email doesn't exists
                      </div>
                    </td>
                  ) : (
                    <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          validateEmail(index);
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                      >
                        validate
                      </button>
                    </td>
                  )} */}
                      <td className="py-[9px] px-4 text-justify text-base font-sans font-normal">
                        {"logs"}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-base font-sans font-normal">
                        {"Remarks"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row justify-between my-3">
              <div className="flex flex-row">
                {page === totalPages ? (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {filteredLeads.length} of {filteredLeads.length} entries{" "}
                  </div>
                ) : (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {page * entriesToShow} of {filteredLeads.length} entries{" "}
                  </div>
                )}
                {
                  <div className="font-light text-base ml-2">
                    {showFilteredFrom &&
                      `(filtered from ${leads.length} entries)`}
                  </div>
                }
              </div>
              <div className="flex justify-center space-x-2">
                {/* Previous Button */}
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Previous
                </button>

                {/* Page Numbers */}
                {totalPages <= 5 ? (
                  // Show all pages if totalPages is less than or equal to 5
                  Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === index + 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {/* First page */}
                    <button
                      onClick={() => handlePageChange(1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      1
                    </button>

                    {/* Second page */}
                    {page === 2 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === 2 ? "bg-slate-500 text-white" : ""
                        }`}
                      >
                        2
                      </button>
                    )}

                    {/* Ellipsis */}
                    {page > 3 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}

                    {/* Current page */}
                    {page > 2 && page < totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(page)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white bg-slate-500 text-white`}
                      >
                        {page}
                      </button>
                    )}

                    {/* Ellipsis for remaining pages */}
                    {page < totalPages - 2 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}
                    {page === totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === totalPages - 1
                            ? "bg-slate-500 text-white"
                            : ""
                        }`}
                      >
                        {totalPages - 1}
                      </button>
                    )}

                    {/* Last page */}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === totalPages ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {totalPages}
                    </button>
                  </>
                )}

                {/* Next Button */}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showScheduleMeeting && (
        <ScheduleMeeting
          selectedLeads={selectedLeads}
          setShowScheduleMeeting={setShowScheduleMeeting}
        />
      )}
      {showEmailModel && (
        <EmailModel
          setShowEmailModel={setShowEmailModel}
          selectedLeads={selectedLeads}
        />
      )}
      {/* {showAddLead && <AddLeadForm />} */}
      {showWhatsAppSend && (
        <WhatsAppCon
          selectedLeads={selectedLeads}
          setSendWhatsApp={setShowWhatsAppSend}
        />
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};
