import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";

export const Home = () => {
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIP(response.data.ip);
        // console.log(response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIP();
  }, []);
  return (
    <div
      className="w-full h-full bg-slate-400"
      style={{ backgroundColor: "#b8e3f6", scrollBehavior: "smooth" }}
    >
      {<Navbar />}
      <div className="mt-10 font-roboto text-5xl font-medium">
        The Advantages <br /> Using Our Services
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <div className="grid max-w-2xl grid-cols-1 gap-x-24 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div
            className="bg-white p-8  border-amber-600  sm:p-8 rounded-3xl shadow-md flex flex-col h-full border-t-4 border-amber-700 transition-all duration-300 ease-in-out hover:shadow-2xl"
            style={{ opacity: "1", transform: "none" }}
          >
            <div className="flex items-center mb-4 sm:mb-6">
              <div className="p-2 bg-amber-600 sm:p-3 rounded-full bg-amber-600 mr-3 sm:mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-briefcase w-6 h-6 sm:w-8 sm:h-8 text-white"
                >
                  <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                  <rect width="20" height="14" x="2" y="6" rx="2"></rect>
                </svg>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
                Career Counselling
              </h2>
            </div>
            <p className="text-gray-600 mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
              Margdarshak has created authentic tools and methodologies to
              develop careers from ambition to achievement.
            </p>
            <ul className="space-y-2 sm:space-y-3">
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Career Planning
              </li>

              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Education and Admission
              </li>
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Study Organiser
              </li>
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Skills and Placement
              </li>
            </ul>
          </div>
          <div
            className="bg-white p-8 border-amber-600  sm:p-8 rounded-3xl shadow-md flex flex-col h-full border-t-4 border-amber-700 transition-all duration-300 ease-in-out hover:shadow-xl"
            style={{ opacity: "1", transform: "none" }}
          >
            <div className="flex items-center  mb-4 sm:mb-6">
              <div className="p-2 bg-amber-600 sm:p-3 rounded-full bg-amber-600 mr-3 sm:mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-building2 w-6 h-6 sm:w-8 sm:h-8 text-white"
                >
                  <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"></path>
                  <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
                  <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"></path>
                  <path d="M10 6h4"></path>
                  <path d="M10 10h4"></path>
                  <path d="M10 14h4"></path>
                  <path d="M10 18h4"></path>
                </svg>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
                Business Consulting
              </h2>
            </div>
            <p className="text-gray-600 mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
              Margdarshak offers business automation and verified data to boost
              new start-ups and existing businesses.
            </p>
            <ul className="space-y-2 sm:space-y-3">
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Brand Compliance
              </li>
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Verified Data
              </li>
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                Communication CRM
              </li>
              <li className="cursor-pointer flex items-center text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm sm:text-base transform transition-transform hover:translate-x-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400"
                >
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
                S-Mart Network
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center gap-6 sm:gap-8 p-6 sm:p-8">
        <button className="flex bg-amber-600 items-center gap-3 px-6 py-4 text-white rounded-lg shadow-lg hover:bg-amber-500 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-custom-100 focus:ring-offset-2 text-lg sm:text-xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-smile w-6 h-6"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
            <line x1="9" x2="9.01" y1="9" y2="9"></line>
            <line x1="15" x2="15.01" y1="9" y2="9"></line>
          </svg>
          <span>Get a Demo</span>
        </button>
        <Link to="/login">
          <button className="flex bg-amber-600 items-center gap-3 px-6 py-4 hover:bg-amber-500 text-white rounded-lg shadow-lg hover:bg-amber-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-custom-300 focus:ring-offset-2 text-lg sm:text-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-rocket w-6 h-6"
            >
              <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
              <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
              <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
              <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
            </svg>
            <span>Start with Free</span>
          </button>
        </Link>
      </div>
      <section className="bg-background py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-semibold tracking-tight text-foreground sm:text-4xl">
              Comprehensive Business Solutions
            </p>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              Our business consultations are built on four fundamental
              components that ensure your success in today's competitive market.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-5xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-12 gap-y-12 lg:max-w-none lg:grid-cols-2">
              <div className="relative pl-16 ">
                <dt className="text-xl font-semibold leading-7 text-foreground">
                  <div className="absolute left-0 top-1 flex h-12 w-12 items-center justify-center rounded-xl bg-amber-600 shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-badge-check h-6 w-6 text-white"
                      aria-hidden="true"
                    >
                      <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"></path>
                      <path d="m9 12 2 2 4-4"></path>
                    </svg>
                  </div>
                  Brand Compliance
                </dt>
                <dd className="mt-3 text-base leading-7 text-muted-foreground">
                  The foundation of a business starts with creating a unique
                  identity for a company, product, service or person. Also,
                  adhering to statutory compliance and standards that are
                  mandated by the regulatory bodies.
                </dd>
              </div>
              <div className="relative pl-16 ">
                <dt className="text-xl font-semibold leading-7 text-foreground">
                  <div className="absolute left-0 top-1 flex h-12 w-12 items-center justify-center rounded-xl bg-amber-600 shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-database h-6 w-6 text-white"
                      aria-hidden="true"
                    >
                      <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                      <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                      <path d="M3 12A9 3 0 0 0 21 12"></path>
                    </svg>
                  </div>
                  Verified Data
                </dt>
                <dd className="mt-3 text-base leading-7 text-muted-foreground">
                  Personally verified, need-based, curated data on Employment,
                  Businesses, Learners, Matri, Properties and Service/Solution
                  Providers. Data can be filtered by country, state, district,
                  PIN, and place to generate leads as per the business
                  requirements.
                </dd>
              </div>
              <div className="relative pl-16 ">
                <dt className="text-xl font-semibold leading-7 text-foreground">
                  <div className="absolute left-0 top-1 flex h-12 w-12 items-center justify-center rounded-xl bg-amber-600 shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-message-square-share h-6 w-6 text-white"
                      aria-hidden="true"
                    >
                      <path d="M21 12v3a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h7"></path>
                      <path d="M16 3h5v5"></path>
                      <path d="m16 8 5-5"></path>
                    </svg>
                  </div>
                  Communication CRM
                </dt>
                <dd className="mt-3 text-base leading-7 text-muted-foreground">
                  Unified secured communication system to connect with
                  prospective customers safely. You can call, email, SMS,
                  WhatsApp, or Meet virtually. Features include masking
                  customers' contact details, maintaining logs of every
                  interaction, and task reminders.
                </dd>
              </div>
              <div className="relative pl-16 ">
                <dt className="text-xl font-semibold leading-7 text-foreground">
                  <div className="absolute left-0 top-1 flex h-12 w-12 items-center justify-center rounded-xl bg-amber-600 shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-network h-6 w-6 text-white"
                      aria-hidden="true"
                    >
                      <rect x="16" y="16" width="6" height="6" rx="1"></rect>
                      <rect x="2" y="16" width="6" height="6" rx="1"></rect>
                      <rect x="9" y="2" width="6" height="6" rx="1"></rect>
                      <path d="M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3"></path>
                      <path d="M12 12V8"></path>
                    </svg>
                  </div>
                  S-Mart Network
                </dt>
                <dd className="mt-3 text-base leading-7 text-muted-foreground">
                  Smart tools and marketplace to manage various functions of
                  your business and offer products and services globally. Also,
                  a business network of professionals that will help you to
                  support and grow your business.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
      <div className="min-h-screen bg-gray-100 ">
        <div className="container mx-auto px-12 py-16">
          <h1 className="text-4xl font-bold text-center mb-8 text-black">
            Margdarshak's Platform
          </h1>
          <p className="mt-6 text-lg mb-12 px-28 text-center leading-8 text-muted-foreground">
            Use Margdarshak's platform to manage your marketing, sales,
            customers, operations, human resources, banking, accounting and
            other activities
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-megaphone w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="m3 11 18-5v12L3 14v-3z"></path>
                  <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Marketing
                </h3>
              </div>
              <p className="text-gray-600">
                Generate abundant leads with branding and not spamming.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-shopping-cart w-8 h-8 text-amber-600 mr-3"
                >
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">Sales</h3>
              </div>
              <p className="text-gray-600">
                Integrate the sales process of any product/service and increase
                5X revenues.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-users w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">Hiring</h3>
              </div>
              <p className="text-gray-600">
                Recruit the best people ready to work full/part-time.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-graduation-cap w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M21.42 10.922a1 1 0 0 0-.019-1.838L12.83 5.18a2 2 0 0 0-1.66 0L2.6 9.08a1 1 0 0 0 0 1.832l8.57 3.908a2 2 0 0 0 1.66 0z"></path>
                  <path d="M22 10v6"></path>
                  <path d="M6 12.5V16a6 3 0 0 0 12 0v-3.5"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Counselling
                </h3>
              </div>
              <p className="text-gray-600">
                Guide students for career planning, and admissions in
                India/abroad.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-book-open w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M12 7v14"></path>
                  <path d="M3 18a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5a4 4 0 0 1 4 4 4 4 0 0 1 4-4h5a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-6a3 3 0 0 0-3 3 3 3 0 0 0-3-3z"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Training
                </h3>
              </div>
              <p className="text-gray-600">
                Teach for academic/competitive exams or skills development.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-shopping-bag w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path>
                  <path d="M3 6h18"></path>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">Mart</h3>
              </div>
              <p className="text-gray-600">
                Showcase and sell your products through a virtual shop.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-briefcase w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                  <rect width="20" height="14" x="2" y="6" rx="2"></rect>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Service
                </h3>
              </div>
              <p className="text-gray-600">
                Offer services and solutions based on your knowledge and
                expertise.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-building w-8 h-8 text-amber-600 mr-3"
                >
                  <rect width="16" height="20" x="4" y="2" rx="2" ry="2"></rect>
                  <path d="M9 22v-4h6v4"></path>
                  <path d="M8 6h.01"></path>
                  <path d="M16 6h.01"></path>
                  <path d="M12 6h.01"></path>
                  <path d="M12 10h.01"></path>
                  <path d="M12 14h.01"></path>
                  <path d="M16 10h.01"></path>
                  <path d="M16 14h.01"></path>
                  <path d="M8 10h.01"></path>
                  <path d="M8 14h.01"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">Work</h3>
              </div>
              <p className="text-gray-600">
                Find opportunities to work full/part-time on a regular salary.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-heart w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">Matri</h3>
              </div>
              <p className="text-gray-600">
                Get advisor-assisted personally verified matrimonial alliances.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-house w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8"></path>
                  <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Properties
                </h3>
              </div>
              <p className="text-gray-600">
                Search properties and accommodations available for rent or sale.
              </p>
            </div>
            <div className="bg-white rounded-3xl shadow-md p-6 hover:shadow-lg ">
              <div className="flex items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-headphones w-8 h-8 text-amber-600 mr-3"
                >
                  <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
                </svg>
                <h3 className="text-xl font-semibold text-amber-700">
                  Support
                </h3>
              </div>
              <p className="text-gray-600">
                Provide real-time support to keep your customers happy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="pricing"
        className="min-h-screen bg-gradient-to-br from-orange-100 to-custom-100 p-4 md:p-8"
      >
        <div className="max-w-4xl mt-16 mx-auto bg-white rounded-3xl shadow-xl p-6 md:p-8">
          <div className="flex items-center justify-center mb-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-indian-rupee w-8 h-8 text-amber-600 mr-2"
            >
              <path d="M6 3h12"></path>
              <path d="M6 8h12"></path>
              <path d="m6 13 8.5 8"></path>
              <path d="M6 13h3"></path>
              <path d="M9 13c6.667 0 6.667-10 0-10"></path>
            </svg>
            <h2 className="text-3xl font-bold text-amber-700">Pricing</h2>
          </div>
          <p className="text-center text-gray-600 mb-8">
            Get your complete business set up with a very nominal monthly or
            annual subscription and recharge any add-on as and when required.
          </p>
          <div className="flex items-center justify-center mb-8">
            <span className="mr-2 text-amber-700 font-semibold">
              Subscription Rate
            </span>
            <button className="flex items-center bg-amber-600 text-white px-3 py-1 rounded-full focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-toggle-right w-4 h-4 mr-1"
              >
                <rect width="20" height="12" x="2" y="6" rx="6" ry="6"></rect>
                <circle cx="16" cy="12" r="2"></circle>
              </svg>
              <span className="text-sm">Subscription Rate</span>
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-custom-100 text-white">
                  <th className="p-2 text-left">Payment</th>
                  <th className="p-2 text-right">Standard**</th>
                  <th className="p-2 text-right">Professional**</th>
                  <th className="p-2 text-right">Business**</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-custom-100">
                  <td className="p-2 font-semibold text-amber-700">Annual</td>
                  <td className="p-2 text-right">₹980</td>
                  <td className="p-2 text-right">₹2,700</td>
                  <td className="p-2 text-right">₹4,400</td>
                </tr>
                <tr className="border-b border-custom-100">
                  <td className="p-2 font-semibold text-amber-700">
                    Half-Yearly
                  </td>
                  <td className="p-2 text-right">₹1,274</td>
                  <td className="p-2 text-right">₹3,240</td>
                  <td className="p-2 text-right">₹4,800</td>
                </tr>
                <tr className="border-b border-custom-100">
                  <td className="p-2 font-semibold text-amber-700">
                    Quarterly
                  </td>
                  <td className="p-2 text-right">₹1,568</td>
                  <td className="p-2 text-right">₹3,780</td>
                  <td className="p-2 text-right">₹5,280</td>
                </tr>
                <tr className="border-b border-custom-100">
                  <td className="p-2 font-semibold text-amber-700">Monthly</td>
                  <td className="p-2 text-right">₹1,862</td>
                  <td className="p-2 text-right">₹4,320</td>
                  <td className="p-2 text-right">₹5,720</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            <p>* GST @18% extra</p>
            <p>** Per user</p>
          </div>
        </div>
      </div>
      <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.custom.100),white)] opacity-20"></div>
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-amber-600/10 ring-1 ring-custom-100 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <div className="flex items-center justify-center space-x-2">
            <h2 className="text-2xl font-semibold text-gray-900">
              Testimonials
            </h2>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-message-circle mx-auto h-12 w-12 text-amber-600"
          >
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
          </svg>
          <div className="relative mt-10 space-y-8">
            <div className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                className="inline text-4xl text-gray-500 mr-2"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.318.142-.686.238-1.028.466-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.945-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 6.5 10zm11 0c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 17.5 10z"></path>
              </svg>
              <p className="inline">
                Margdarshak has transformed our customer engagement strategy,
                providing a secure and reliable platform for our team.
              </p>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                className="inline text-4xl text-gray-500 ml-2"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m21.95 8.721-.025-.168-.026.006A4.5 4.5 0 1 0 17.5 14c.223 0 .437-.034.65-.065-.069.232-.14.468-.254.68-.114.308-.292.575-.469.844-.148.291-.409.488-.601.737-.201.242-.475.403-.692.604-.213.21-.492.315-.714.463-.232.133-.434.28-.65.35l-.539.222-.474.197.484 1.939.597-.144c.191-.048.424-.104.689-.171.271-.05.56-.187.882-.312.317-.143.686-.238 1.028-.467.344-.218.741-.4 1.091-.692.339-.301.748-.562 1.05-.944.33-.358.656-.734.909-1.162.293-.408.492-.856.702-1.299.19-.443.343-.896.468-1.336.237-.882.343-1.72.384-2.437.034-.718.014-1.315-.028-1.747a7.028 7.028 0 0 0-.063-.539zm-11 0-.025-.168-.026.006A4.5 4.5 0 1 0 6.5 14c.223 0 .437-.034.65-.065-.069.232-.14.468-.254.68-.114.308-.292.575-.469.844-.148.291-.409.488-.601.737-.201.242-.475.403-.692.604-.213.21-.492.315-.714.463-.232.133-.434.28-.65.35l-.539.222c-.301.123-.473.195-.473.195l.484 1.939.597-.144c.191-.048.424-.104.689-.171.271-.05.56-.187.882-.312.317-.143.686-.238 1.028-.467.344-.218.741-.4 1.091-.692.339-.301.748-.562 1.05-.944.33-.358.656-.734.909-1.162.293-.408.492-.856.702-1.299.19-.443.343-.896.468-1.336.237-.882.343-1.72.384-2.437.034-.718.014-1.315-.028-1.747a7.571 7.571 0 0 0-.064-.537z"></path>
              </svg>
            </div>
          </div>

          <div className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              className="inline text-4xl text-gray-500 mr-2"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.318.142-.686.238-1.028.466-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.945-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 6.5 10zm11 0c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 17.5 10z"></path>
            </svg>
            <p className="inline">
              The platform's intuitive design and powerful features have
              significantly improved our workflow and collaboration.
            </p>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              className="inline text-4xl text-gray-500 ml-2"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21.95 8.721-.025-.168-.026.006A4.5 4.5 0 1 0 17.5 14c.223 0 .437-.034.65-.065-.069.232-.14.468-.254.68-.114.308-.292.575-.469.844-.148.291-.409.488-.601.737-.201.242-.475.403-.692.604-.213.21-.492.315-.714.463-.232.133-.434.28-.65.35l-.539.222-.474.197.484 1.939.597-.144c.191-.048.424-.104.689-.171.271-.05.56-.187.882-.312.317-.143.686-.238 1.028-.467.344-.218.741-.4 1.091-.692.339-.301.748-.562 1.05-.944.33-.358.656-.734.909-1.162.293-.408.492-.856.702-1.299.19-.443.343-.896.468-1.336.237-.882.343-1.72.384-2.437.034-.718.014-1.315-.028-1.747a7.028 7.028 0 0 0-.063-.539zm-11 0-.025-.168-.026.006A4.5 4.5 0 1 0 6.5 14c.223 0 .437-.034.65-.065-.069.232-.14.468-.254.68-.114.308-.292.575-.469.844-.148.291-.409.488-.601.737-.201.242-.475.403-.692.604-.213.21-.492.315-.714.463-.232.133-.434.28-.65.35l-.539.222c-.301.123-.473.195-.473.195l.484 1.939.597-.144c.191-.048.424-.104.689-.171.271-.05.56-.187.882-.312.317-.143.686-.238 1.028-.467.344-.218.741-.4 1.091-.692.339-.301.748-.562 1.05-.944.33-.358.656-.734.909-1.162.293-.408.492-.856.702-1.299.19-.443.343-.896.468-1.336.237-.882.343-1.72.384-2.437.034-.718.014-1.315-.028-1.747a7.571 7.571 0 0 0-.064-.537z"></path>
            </svg>
            {/* </blockquote> */}
          </div>
        </div>
      </section>
    </div>
  );
};
