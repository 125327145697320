import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindowF,
  LoadScript,
} from "@react-google-maps/api";
import { getDistance } from "geolib";
import { MdOutlineDeliveryDining } from "react-icons/md";
import axios from "axios";

export const TrackEmployeeMap = () => {
  const userID = localStorage.getItem("userID");
  const [trackedPaths, setTrackedPaths] = useState([]);
  const [selectedStartPoint, setSelectedStartPoint] = useState(null);
  const [selectedEndPoint, setSelectedEndPoint] = useState(null);
  const headers = [
    "Name",
    "StartTime",
    "StartLocation",
    "EndLocation",
    "EndTime",
    "Distance",
  ];

  useEffect(() => {
    fetchTrackedPaths();
    // fetchaddress(28.6193236, 77.0286501);
  }, []);

  const calculateTotalDistance = (coordinates) => {
    let totalDistance = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = coordinates[i];
      const end = coordinates[i + 1];

      const distance = getDistance(
        { latitude: start.lat, longitude: start.lng },
        { latitude: end.lat, longitude: end.lng }
      );

      totalDistance += distance; // Distance is in meters
    }

    return totalDistance / 1000; // Convert to kilometers
  };

  const convertToIST = (utcDate) => {
    const utcDateObj = new Date(utcDate);
    return utcDateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  };

  const fetchNames = async (userID) => {
    try {
      const response = await axios.post(
        "https://margda.in:7000/api/getuserdata",
        { userID }
      );
      if (response.status === 200) {
        return response.data.User[0].name ? response.data.User[0].name : "N/A";
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          console.log(error.response);
          return "N/A";
        }
      }
    }
  };

  const fetchTrackedPaths = async () => {
    const response = await fetch(
      "https://margda.in:7000/api/android/getTrackedPaths",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      const addName = await Promise.all(
        data.TrackedPaths.map(async (item) => {
          const name = await fetchNames(item.userID);
          const distance = calculateTotalDistance(item.trackpath);
          item.Distance = distance;
          item.Name = name;
          const startTime = convertToIST(item.edate);
          const endTime = convertToIST(item.endDateTime);
          // if (item.EndLocation === null) {
          //   const endLocation = await fetchaddress(
          //     item.trackpath[item.trackpath.length - 1].lat,
          //     item.trackpath[item.trackpath.length - 1].lng
          //   );
          //   item.EndLocation = endLocation;
          // }
          item.StartTime = startTime;
          item.EndTime = endTime;
          return item;
        })
      );
      setTrackedPaths(addName);
    }
  };
  const mapContainerStyle = {
    width: "100%",
    height: "400px", // Adjust the height
  };

  const center = {
    lat: 28.59100325186512, // Latitude
    lng: 77.19316005706787, // Longitude
  };
  const [path, setPath] = useState([]);
  const [mapCenter, setMapCenter] = useState(center);

  const handlePreviousGeofenceChange = async (e) => {
    if (e.target.value === "") {
      return;
    }
    setPath(trackedPaths[e.target.value].trackpath);
    setMapCenter(trackedPaths[e.target.value].trackpath[0]);
  };

  return (
    <div>
      <div className="bg-gray-800 text-white text-center py-3 mb-4">
        Tracked Paths
      </div>
      <div className="mb-4">
        <select
          name="geofences"
          id="geofences"
          onChange={handlePreviousGeofenceChange}
        >
          <option value="">Select Tracked Paths</option>
          {trackedPaths.length > 0 &&
            trackedPaths.map((trackedPath, index) => (
              <option value={index}>
                {trackedPath.Name} {trackedPath.StartTime}
              </option>
            ))}
        </select>
      </div>
      {trackedPaths && trackedPaths.length > 0 && (
        <div className="flex justify-center">
          <table className="border-collapse border border-gray-900 w-[90%]">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="border border-gray-300 p-2 bg-gray-200 text-base font-serif"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {trackedPaths.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      className="border border-gray-300 p-2 text-center text-base font-serif"
                    >
                      {colIndex === 0 && row.complete === false ? (
                        <MdOutlineDeliveryDining className="bg-green-300" />
                      ) : (
                        ""
                      )}
                      {header === "Distance" ? (
                        `${row[header]}KM`
                      ) : header === "EndLocation" && row[header] === null ? (
                        <p className="bg-green-300">On the way</p>
                      ) : (
                        row[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <LoadScript
        googleMapsApiKey="AIzaSyB9df0GL02q2s32tlnqXpv71ZKtOQFOHNU"
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={13}
        >
          <Polyline
            path={path}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />

          {path.length > 0 && (
            <Marker
              label="Start"
              position={{
                lat: path[0].lat,
                lng: path[0].lng,
              }}
              onClick={(e) => {
                const latLng = e.latLng;
                const clickedPoint = trackedPaths.find(
                  (data) =>
                    data.trackpath[0].lat === latLng.lat() &&
                    data.trackpath[0].lng === latLng.lng()
                );
                setSelectedStartPoint(clickedPoint);
              }}
            />
          )}

          {path.length > 0 && (
            <Marker
              label="End"
              position={{
                lat: path[path.length - 1].lat,
                lng: path[path.length - 1].lng,
              }}
              onClick={(e) => {
                const latLng = e.latLng;
                const clickedPoint = trackedPaths.find(
                  (data) =>
                    data.trackpath[data.trackpath.length - 1].lat ===
                      latLng.lat() &&
                    data.trackpath[data.trackpath.length - 1].lng ===
                      latLng.lng()
                );
                setSelectedEndPoint(clickedPoint);
              }}
            />
          )}

          {selectedStartPoint && (
            <InfoWindowF
              position={{
                lat: selectedStartPoint.trackpath[0].lat,
                lng: selectedStartPoint.trackpath[0].lng,
              }}
              onCloseClick={() => setSelectedStartPoint(null)}
            >
              <div>
                <h5>{selectedStartPoint.StartLocation}</h5>
                <h4>User Information</h4>
                <p>
                  <strong>Time:</strong>{" "}
                  {new Date(selectedStartPoint.edate).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  })}
                </p>
              </div>
            </InfoWindowF>
          )}
          {selectedEndPoint && (
            <InfoWindowF
              position={{
                lat: selectedEndPoint.trackpath[
                  selectedEndPoint.trackpath.length - 1
                ].lat,
                lng: selectedEndPoint.trackpath[
                  selectedEndPoint.trackpath.length - 1
                ].lng,
              }}
              onCloseClick={() => setSelectedEndPoint(null)}
            >
              <div>
                <h5>{selectedEndPoint.EndLocation}</h5>
                <h4>User Information</h4>
                <p>
                  <strong>Time:</strong>{" "}
                  {new Date(selectedEndPoint.endDateTime).toLocaleString(
                    "en-IN",
                    {
                      timeZone: "Asia/Kolkata",
                    }
                  )}
                </p>
              </div>
            </InfoWindowF>
          )}
        </GoogleMap>
      </LoadScript>
      <div className="flex flex-row justify-around mt-10"></div>
    </div>
  );
};
