import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AddFooter = () => {
  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [share, setShare] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isHtmlContent, setIsHtmlContent] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    const text = state.getCurrentContent().getPlainText();
    setMessage(text);
    setErrors({ ...errors, ["message"]: "" });
  };

  const onHandleKeyBindings = (e) => {
    if (e.keyCode === 9) {
      setEditorState(RichUtils.onTab(e, editorState, 4));
    } else {
      return getDefaultKeyBinding(e);
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (!templateName.trim()) {
      newErrors.templateName = "Template Name is required.";
    }
    if (!message) {
      newErrors.message = "Content is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const userID = localStorage.getItem("userID");
    let payload = {
      templateName,
      share,
      content: message,
      userID,
    };
    console.log(payload);
    const apiUrl = "https://margda.in:7000/api/margda.org/templates/add-footer";
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        return toast.success(data.message);
      } else {
        return toast.error(data.message);
      }
    } catch (error) {
      return toast.error(error);
    }
  };

  const handleHtmlChange = (e) => {
    setIsHtmlContent(e.target.checked);
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    console.log(errors);
    setErrors({ ...errors, ["templateName"]: "" });
  };

  return (
    <div className="flex flex-col bg-gray-100 justify-start items-start p-2">
      <div className="text-3xl font-light font-sans mt-4">Footer</div>
      <div
        className="bg-white self-center justify-between flex flex-col p-4 mt-4 rounded border"
        style={{ minWidth: "98%" }}
      >
        <div className="flex flex-row text-base font-normal justify-between w-full">
          <div className="flex flex-col items-start w-full">
            <label htmlFor="template-name" className="font-bold p-1">
              Template
            </label>
            <input
              type="text"
              name="template-name"
              id="template-name"
              value={templateName}
              onChange={handleTemplateNameChange}
              placeholder="Enter Template Name Here"
              className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
            />
            {errors.templateName && (
              <p className="text-red-500 text-sm mt-1">{errors.templateName}</p>
            )}
          </div>
        </div>
        <div className="flex flex-row text-base font-bold justify-between w-[90%] mt-4">
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-row items-center w-full">
              <label
                htmlFor="template-message"
                className="text-base font-bold p-1"
              >
                Content
              </label>
              <div className="flex ml-10 items-center">
                <input
                  type="checkbox"
                  name="switch"
                  id="switch-html"
                  checked={isHtmlContent}
                  className="hidden"
                  onChange={handleHtmlChange}
                />
              </div>
            </div>
            <div id="template-message" className="w-[80%]">
              <div className="border border-slate-700 h-80 w-full overflow-y-scroll">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarclassName="
                  wrapperClassName="wrapperclassName="
                  editorClassName="editorclassName="
                  toolbarCustomButtons={[
                    <label
                      htmlFor="switch-html"
                      className={`text-base font-normal p-1 ${
                        isHtmlContent ? "border bg-gray-100" : ""
                      }`}
                    >
                      Source
                    </label>,
                  ]}
                  onEditorStateChange={onEditorStateChange}
                  onTab={onHandleKeyBindings}
                />
              </div>
              {isHtmlContent && (
                <div className="flex flex-col justify-start items-start">
                  <label
                    htmlFor="preview"
                    className="text-base font-normal p-1"
                  >
                    Preview
                  </label>
                  <div
                    id="preview"
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      marginTop: "10px",
                      overflowX: "scroll",
                      maxHeight: "400px",
                      overflowY: "scroll",
                    }}
                    className="w-full p-2 mb-4 border-gray-300 flex flex-col items-start"
                    dangerouslySetInnerHTML={{
                      __html: message ? message : "Preview Will be Show Here",
                    }}
                  />
                </div>
              )}
            </div>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </div>
        </div>

        <div className="flex flex-row mt-4 items-center ">
          <div className="flex">
            <input
              type="checkbox"
              name=""
              id="share"
              checked={share}
              className="w-5 h-5"
              onChange={(e) => {
                setShare(e.target.checked);
              }}
            />
          </div>
          <div className="">
            <label htmlFor="share" className="ml-4  font-normal">
              Share
            </label>
          </div>
        </div>
        <div className="flex flex-row justify-start gap-3 my-3">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
          >
            Submit
          </button>
          <Link
            to={"/dashboard/footer-list"}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
          >
            Back
          </Link>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};
