import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdFormatListBulleted } from "react-icons/md";
import { IoIosCall } from "react-icons/io";

export const CallReport = () => {
  const name = localStorage.getItem("name");
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allCalls, setAllCalls] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [userID, setUserID] = useState();
  const [callSummary, setCallSummary] = useState([]);

  useEffect(() => {
    fetchCallRecords();
  }, []);

  const fetchCallRecords = async () => {
    const userID = localStorage.getItem("userID");
    setUserID(userID);
    try {
      const response = await axios.post(
        "https://margda.in:7000/api/margda.org/report/call-report",
        { userID }
      );
      if (response.status === 200) {
        const sortedData = response.data.Calls.sort((a, b) => {
          const dateA = new Date(a.call_start);
          const dateB = new Date(b.call_start);
          return dateB - dateA; // Ascending order
        });
        setAllCalls(sortedData);
        setTotalPages(Math.ceil(response.data.Calls.length / entriesToShow));
        setCurrentData(sortedData);
        const incoming = response.data.Calls.filter(
          (item) => item.calltype === "I"
        );
        const outgoing = response.data.Calls.filter(
          (item) => item.calltype === "O"
        );
        const rejected = response.data.Calls.filter(
          (item) => item.calltype === "R"
        );
        const missed = response.data.Calls.filter(
          (item) => item.calltype === "M"
        );
        let incomingDuration = 0;
        incoming.map((item) => {
          if (isNaN(item.duration)) {
            return incomingDuration;
          }
          incomingDuration = incomingDuration + item.duration;
          return incomingDuration;
        });
        let outgoingDuration = 0;
        outgoing.map((item) => {
          if (isNaN(item.duration)) {
            return outgoingDuration;
          }
          outgoingDuration = outgoingDuration + item.duration;
          return outgoingDuration;
        });
        let rejectedDuration = 0;
        rejected.map((item) => {
          if (isNaN(item.duration)) {
            return rejectedDuration;
          }
          rejectedDuration = rejectedDuration + item.duration;
          return rejectedDuration;
        });
        let missedDuration = 0;
        missed.map((item) => {
          if (isNaN(item.duration)) {
            return missedDuration;
          }
          missedDuration = missedDuration + item.duration;
          return missedDuration;
        });
        let summary = [];
        summary.push({
          type: "Incoming",
          number: incoming.length,
          duration: incomingDuration,
        });
        summary.push({
          type: "Outgoing",
          number: outgoing.length,
          duration: outgoingDuration,
        });
        summary.push({
          type: "Rejected",
          number: rejected.length,
          duration: rejectedDuration,
        });
        summary.push({
          type: "Missed",
          number: missed.length,
          duration: missedDuration,
        });
        setCallSummary(summary);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response);
      }
    }
  };

  const filteredCalls = allCalls.filter(
    (call) =>
      (call.caller &&
        call.caller.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (call.receiver &&
        call.receiver.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    if (searchTerm !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    const data = filteredCalls.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );

    setCurrentData(data);
    setTotalPages(Math.ceil(filteredCalls.length / entriesToShow));
  }, [entriesToShow, page, searchTerm]);

  useEffect(() => {
    setPage(1);
  }, [entriesToShow]);

  const convertToIST = (utcDate) => {
    const utcDateObj = new Date(utcDate);
    return utcDateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}h: ${minutes}m: ${remainingSeconds}s`;
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100">
      <div className="mt-4 ml-4 flex flex-col items-start w-full">
        <h2 className="text-2xl font-bold text-xl mb-6 text-gray-700">
          Hello, {name}!
        </h2>
        <p className="font-light text-base">
          Here is your and the Team's Call Report
        </p>
      </div>

      <div className="bg-white px-4 border-1 rounded shadow-lg mt-7 min-w-[95%]">
        <div className="flex justify-start p-3 text-2xl items-center mt-7 cursor-default">
          <IoIosCall className="mr-2" />
          Calls Report
        </div>
        <div className="flex justify-between items-center my-2 w-[100%]">
          <div>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              Show
            </label>
            <select
              id="entries"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(Number(e.target.value))}
              className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              {" "}
              Records
            </label>
          </div>
          <div className="items-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
            />
          </div>
        </div>

        {currentData.length === 0 ? (
          <div className="text-center text-gray-600">No Record found</div>
        ) : (
          <div>
            <div className="rounded-lg shadow-lg max-h-[600px] overflow-y-scroll">
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-800 text-white text-center">
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Date-Time
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Call Type
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      User
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Client
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Talk Time
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Call Recording
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.slice(0, entriesToShow).map((record, index) => (
                    <tr
                      key={index}
                      className="border-t hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                        {convertToIST(record.call_start)}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                        {record.calltype}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                        {record.caller}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                        {record.receiver}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                        {formatTime(record.duration)}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-[50px]">
                        {record.call_url ? (
                          <iframe
                            width="350px"
                            height="55px"
                            className="rounded-lg border-2 border-slate-500"
                            sandbox="allow-same-origin allow-scripts allow-presentation"
                            src={record.call_url.split("view")[0] + "preview"}
                          ></iframe>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row justify-between my-3">
              <div className="flex flex-row">
                {page === totalPages ? (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {filteredCalls.length} of {filteredCalls.length} entries{" "}
                  </div>
                ) : (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {page * entriesToShow} of {filteredCalls.length} entries{" "}
                  </div>
                )}
                {
                  <div className="font-light text-base ml-2">
                    {showFilteredFrom &&
                      `(filtered from ${allCalls.length} entries)`}
                  </div>
                }
              </div>
              <div className="flex justify-center space-x-2">
                {/* Previous Button */}
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Previous
                </button>

                {/* Page Numbers */}
                {totalPages <= 5 ? (
                  // Show all pages if totalPages is less than or equal to 5
                  Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === index + 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {/* First page */}
                    <button
                      onClick={() => handlePageChange(1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      1
                    </button>

                    {/* Second page */}
                    {page === 2 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === 2 ? "bg-slate-500 text-white" : ""
                        }`}
                      >
                        2
                      </button>
                    )}

                    {/* Ellipsis */}
                    {page > 3 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}

                    {/* Current page */}
                    {page > 2 && page < totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(page)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white bg-slate-500 text-white`}
                      >
                        {page}
                      </button>
                    )}

                    {/* Ellipsis for remaining pages */}
                    {page < totalPages - 2 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}
                    {page === totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === totalPages - 1
                            ? "bg-slate-500 text-white"
                            : ""
                        }`}
                      >
                        {totalPages - 1}
                      </button>
                    )}

                    {/* Last page */}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === totalPages ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {totalPages}
                    </button>
                  </>
                )}

                {/* Next Button */}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-start p-3 text-2xl items-center cursor-default">
          <MdFormatListBulleted className="text-3xl mr-4" />
          Call Summary
        </div>
        <div className="rounded-lg shadow-lg max-h-[600px] overflow-y-scroll mb-4">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-800 text-white text-center">
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Call Type
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Total Calls
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Talk Time
                </th>
              </tr>
            </thead>
            <tbody>
              {callSummary.map((record, index) => (
                <tr
                  key={index}
                  className="border-t hover:bg-gray-100 cursor-pointer"
                >
                  <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                    {record.type}
                  </td>
                  <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                    {record.number}
                  </td>
                  <td className="py-[9px] px-4 text-justify text-sm font-sans font-normal min-w-50">
                    {formatTime(record.duration)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
