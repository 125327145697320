import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaEdit, FaTrash } from "react-icons/fa"; // Import eye icons
import axios from "axios";

export const Users = () => {
  const [currentData, setCurrentData] = useState([]);
  const [users, setUsers] = useState([]);
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllUsers();
  }, []);
  const fetchAllUsers = async () => {
    const response = await axios.post(
      "https://margda.in:7000/api/margda.org/admin/getallusers"
    );
    console.log(response);
    if (response.status === 200 && response.data.data) {
      setUsers(response.data.data);
      setTotalPages(Math.ceil(response.data.data.length / entriesToShow));
      setCurrentData(response.data.data);
    }
  };
  const handleCheckboxChange = (index) => {
    const selectedUser = users[index];
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(selectedUser)
        ? prevSelectedUsers.filter((user) => user !== selectedUser)
        : [...prevSelectedUsers, selectedUser]
    );
  };

  const handleSelectAll = (isChecked) => {
    setSelectedUsers(isChecked ? [...users] : []);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      user.email.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      user.mobile.includes(searchKeyword)
  );

  useEffect(() => {
    const data = filteredUsers.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );
    setCurrentData(data);
    setTotalPages(Math.ceil(filteredUsers.length / entriesToShow));
  }, [entriesToShow, page, searchKeyword]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchKeyword]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg min-h-screen mt-8 shadow-lg w-full max-w-[98%]">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex flex-col items-end gap-[3px] ">
            <div className="w-[4px] h-[4px] bg-slate-700"></div>
            <div className="w-[4px] h-[4px] bg-slate-700"></div>
            <div className="w-[4px] h-[4px] bg-slate-700"></div>
          </div>
          <div
            className="flex flex-col items-start gap-[3px] ml-[1px]"
            style={{ marginLeft: "3px" }}
          >
            <div className="w-5 h-[4px] bg-slate-700"></div>
            <div className="w-5 h-[4px] bg-slate-700"></div>
            <div className="w-5 h-[4px] bg-slate-700"></div>
          </div>

          <div className="text-black font-light">Users List</div>
        </div>
        <div
          style={{
            backgroundColor: "rgb(184 197 225)",
            width: "100%",
            height: "1px",
            marginTop: "8px",
          }}
        ></div>
        <div className="flex justify-between items-center my-5">
          <div>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              Show Entries:
            </label>
            <select
              id="entries"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(Number(e.target.value))}
              className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
            />
          </div>
        </div>
        <div>
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-800 text-white text-center">
                <th className="py-3 px-4">
                  <input
                    type="checkbox"
                    checked={selectedUsers.length === users.length}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Name
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Mobile
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Whatsapp
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Email
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Gender
                </th>
                <th className="py-[9px] px-4 text-start uppercase font-semibold text-sm min-w-50">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.slice(0, entriesToShow).map((user, index) => (
                <tr
                  key={index}
                  className="border-t hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => {
                    // Prevent toggle when clicking directly on the checkbox
                    if (e.target.type !== "checkbox") {
                      handleCheckboxChange(index);
                    }
                  }}
                >
                  <td className="py-[9px] px-4">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user)}
                      className="cursor-pointer"
                      onChange={() => handleCheckboxChange(index)}
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    />
                  </td>
                  <td className="py-[9px] px-4 text-start text-base font-sans font-normal min-w-50">
                    {user.name}
                  </td>
                  <td className="py-[9px] px-4 text-start text-base font-sans font-normal min-w-50">
                    {user.mobile}
                  </td>
                  <td className="py-[9px] px-4 text-start text-base font-sans font-normal min-w-50">
                    {user.whatsapp}
                  </td>
                  <td className="py-[9px] px-4 text-start text-base font-sans font-normal min-w-50">
                    {user.email}
                  </td>
                  <td className="py-[9px] px-4 text-start text-base font-sans font-normal min-w-50">
                    {user.gender}
                  </td>

                  <td className="py-[9px] px-4 flex justify-around">
                    <button
                      className="text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <FaEdit />
                    </button>
                    <FaTrash
                      className="cursor-pointer text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            backgroundColor: "rgb(184 197 225)",
            width: "100%",
            height: "1px",
            marginTop: "8px",
          }}
        ></div>
        <div className="flex flex-row justify-between mt-3">
          <div className="flex flex-row">
            {page === totalPages ? (
              <div className="text-base font-light ">
                Showing {(page - 1) * entriesToShow + 1} to{" "}
                {filteredUsers.length} of {filteredUsers.length} entries{" "}
              </div>
            ) : (
              <div className="text-base font-light">
                Showing {(page - 1) * entriesToShow + 1} to{" "}
                {page * entriesToShow} of {filteredUsers.length} entries{"    "}
              </div>
            )}
            {
              <div className="font-light text-base ml-2">
                {showFilteredFrom && `(filtered from ${users.length} entries)`}
              </div>
            }
          </div>
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
            >
              Previous
            </button>

            {/* Page Numbers */}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                  page === index + 1 ? "bg-slate-500 text-white" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
