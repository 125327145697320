import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../Components/Loader";

export const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [unsubscribe, setUnsubscribe] = useState(false);
  const [title, setTitle] = useState(
    "Do you want to unsubscribe email from this sender?"
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const unikey = searchParams.get("unikey");
  const dataID = searchParams.get("dataid");
  const userID = searchParams.get("userid");
  const euser = searchParams.get("euser");

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda.org/unsubscribe_information",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dataID,
            userID,
            euser,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        const unsubs = data.data[data.data.length - 1].unsubs;
        console.log(unsubs);
        setUnsubscribe(!unsubs);
        if (unsubs) {
          setTitle(
            `You have unsubscribed the email from this sender. Do you want to subscribe it again?`
          );
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda.org/unsubscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            unikey,
            dataID,
            userID,
            euser,
            unsubscribe,
          }),
        }
      );
      if (response.ok) {
        if (unsubscribe) {
          setMessage("Unsubscribed successfully");
          setTitle(
            `You have unsubscribed the email from this sender. Do you want to subscribe it again?`
          );
          setUnsubscribe(false);
        } else {
          setMessage("Subscribed successfully");
          setTitle("Do you want to unsubscribe email from this sender?");
          setUnsubscribe(true);
        }
      } else {
        setMessage("Error in unsubscring");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("Error in unsubscribing", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-[100vh] text-xl">
      {loading ? <Loader /> : <></>}
      <div className="border w-[40%]">
        <h2 className="border border-slate-400 p-3">{title}</h2>
        <div className="flex justify-start p-6">
          <div>
            <input
              type="checkbox"
              name="subscribe"
              id="subscribe"
              checked={!unsubscribe}
              onChange={(e) => {
                if (e.target.checked) {
                  setUnsubscribe(false);
                }
              }}
            />
            <label htmlFor="subscribe" className="ml-1">
              Subscribe
            </label>
          </div>
          <div className="ml-5">
            <input
              type="checkbox"
              name="unsubscribe"
              id="unsubscribe"
              checked={unsubscribe}
              onChange={(e) => {
                if (e.target.checked) {
                  setUnsubscribe(true);
                }
              }}
            />
            <label htmlFor="unsubscribe" className="ml-1">
              Unsubscribe
            </label>
          </div>
        </div>
        <div className="flex justify-start p-6 border">
          <button
            onClick={submit}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
          >
            Submit
          </button>
        </div>

        <div className="flex justify-start p-6 border">{message}</div>
      </div>
    </div>
  );
};
