import { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

export const FooterList = () => {
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentData, setCurrentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [footers, setFooters] = useState([]);
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    fetchFooters();
  }, []);

  const fetchFooters = async () => {
    const userId = localStorage.getItem("userID");
    setUserID(userId);
    console.log(userId);
    if (!userId) return setFooters([]);

    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda.org/templates/get-footers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userID: userId }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch footers");
      const data = await response.json();

      console.log(data.Footers);
      setFooters(data.Footers);
      setTotalPages(Math.ceil(data.Footers.length / entriesToShow));
      setCurrentData(data.Footers);
    } catch (error) {
      console.error("Error fetching footers:", error);
    }
  };

  useEffect(() => {
    const data = filteredFooters.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );

    setCurrentData(data);
    setTotalPages(Math.ceil(filteredFooters.length / entriesToShow));
  }, [entriesToShow, page, searchTerm]);

  useEffect(() => {
    setPage(1);
  }, [entriesToShow]);

  const filteredFooters = footers.filter(
    (template) =>
      (template.template &&
        template.template.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (template.temptype &&
        template.temptype.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleEditLead = async (dataID) => {
    // try {
    //   const response = await fetch(
    //     `https://margda.in:7000/api/margda.org/edit-data`,
    //     {
    //       method: "PUT",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ dataID, userID }),
    //     }
    //   );
    // } catch (error) {
    //   console.log(error);
    //   alert(error);
    // }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    if (searchTerm !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchTerm]);

  return (
    <div>
      <div
        className="bg-gray-100 flex flex-row justify-center items-center"
        style={{ width: "100%" }}
      >
        <div
          className="flex justify-center flex-col items-between bg-white m-5"
          style={{ minWidth: "95%" }}
        >
          <div className="flex flex-row justify-between m-5">
            <div>Footer</div>
            <div>
              <Link
                to={"/dashboard/add-footer"}
                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
              >
                Add Footer
              </Link>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(184 197 225)",
              width: "100%",
              height: "1px",
            }}
          ></div>
          <div className="bg-white px-4 border-1 rounded shadow-lg">
            <div className="flex justify-between items-center my-2 w-[100%]">
              <div>
                <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
                  Show Entries:
                </label>
                <select
                  id="entries"
                  value={entriesToShow}
                  onChange={(e) => setEntriesToShow(Number(e.target.value))}
                  className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div className="items-end">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
                />
              </div>
            </div>

            {currentData.length === 0 ? (
              <div className="text-center text-gray-600 p-20">
                No footers found
              </div>
            ) : (
              <div>
                <div className="rounded-lg shadow-lg max-h-[600px] overflow-y-scroll min-h-[400px]">
                  <table className="min-w-full table-auto border-collapse">
                    <thead>
                      <tr className="bg-gray-800 text-white text-center  flex justify-between">
                        <th className="w-1/3 py-3 pl-5 text-justify uppercase font-semibold text-sm">
                          Footer
                        </th>
                        <th className="w-1/3 py-3 text-justify uppercase font-semibold text-sm">
                          Content
                        </th>
                        <th className="w-1/3 py-3 text-justify uppercase font-semibold text-sm">
                          Share
                        </th>
                        <th className="py-3 text-justify uppercase font-semibold text-sm flex justify-end pr-16">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData
                        .slice(0, entriesToShow)
                        .map((footer, index) => (
                          <tr
                            key={index}
                            className="border-t hover:bg-gray-100 cursor-pointer flex border"
                          >
                            <td className="w-1/3 py-3 pl-5 text-justify text-base font-sans font-normal ">
                              {footer.template}
                            </td>
                            <td className="w-1/3 py-3 text-justify  items-center flex text-base font-sans font-normal ">
                              {footer.content}
                            </td>
                            <td className="w-1/3 py-3  text-justify text-base font-sans font-normal ">
                              {footer.share ? "Y" : "N"}
                            </td>
                            <td className="py-3  text-justify font-sans font-normal flex justify-end pr-9">
                              {footer.euser == userID && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click
                                    handleEditLead(footer.dataID);
                                  }}
                                >
                                  <FaEdit className="cursor-pointer text-blue-500 ml-2" />
                                </button>
                              )}

                              <button
                                className="cursor-pointer text-blue-500 ml-2"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent row click
                                  // handleDeleteLead(lead.dataID);
                                }}
                              >
                                <FaTrash />
                              </button>
                              <button>
                                <FaEye
                                  className="cursor-pointer text-blue-500 ml-2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-row justify-between my-3">
                  <div className="flex flex-row">
                    {page === totalPages ? (
                      <div className="text-base font-light ">
                        Showing {(page - 1) * entriesToShow + 1} to{" "}
                        {filteredFooters.length} of {filteredFooters.length}{" "}
                        entries{" "}
                      </div>
                    ) : (
                      <div className="text-base font-light ">
                        Showing {(page - 1) * entriesToShow + 1} to{" "}
                        {page * entriesToShow} of {filteredFooters.length}{" "}
                        entries{" "}
                      </div>
                    )}
                    {
                      <div className="font-light text-base ml-2">
                        {showFilteredFrom &&
                          `(filtered from ${footers.length} entries)`}
                      </div>
                    }
                  </div>
                  <div className="flex justify-center space-x-2">
                    {/* Previous Button */}
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                      className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                    >
                      Previous
                    </button>

                    {/* Page Numbers */}
                    {totalPages <= 5 ? (
                      // Show all pages if totalPages is less than or equal to 5
                      Array.from({ length: totalPages }, (_, index) => (
                        <button
                          key={index}
                          onClick={() => handlePageChange(index + 1)}
                          className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                            page === index + 1 ? "bg-slate-500 text-white" : ""
                          }`}
                        >
                          {index + 1}
                        </button>
                      ))
                    ) : (
                      <>
                        {/* First page */}
                        <button
                          onClick={() => handlePageChange(1)}
                          className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                            page === 1 ? "bg-slate-500 text-white" : ""
                          }`}
                        >
                          1
                        </button>

                        {/* Second page */}
                        {page === 2 && (
                          <button
                            onClick={() => handlePageChange(2)}
                            className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                              page === 2 ? "bg-slate-500 text-white" : ""
                            }`}
                          >
                            2
                          </button>
                        )}

                        {/* Ellipsis */}
                        {page > 3 && (
                          <span className="px-3 py-2 font-normal text-sm">
                            ...
                          </span>
                        )}

                        {/* Current page */}
                        {page > 2 && page < totalPages - 1 && (
                          <button
                            onClick={() => handlePageChange(page)}
                            className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white bg-slate-500 text-white`}
                          >
                            {page}
                          </button>
                        )}

                        {/* Ellipsis for remaining pages */}
                        {page < totalPages - 2 && (
                          <span className="px-3 py-2 font-normal text-sm">
                            ...
                          </span>
                        )}
                        {page === totalPages - 1 && (
                          <button
                            onClick={() => handlePageChange(2)}
                            className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                              page === totalPages - 1
                                ? "bg-slate-500 text-white"
                                : ""
                            }`}
                          >
                            {totalPages - 1}
                          </button>
                        )}

                        {/* Last page */}
                        <button
                          onClick={() => handlePageChange(totalPages)}
                          className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                            page === totalPages ? "bg-slate-500 text-white" : ""
                          }`}
                        >
                          {totalPages}
                        </button>
                      </>
                    )}

                    {/* Next Button */}
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page === totalPages}
                      className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "rgb(184 197 225)",
          width: "100%",
          height: "1px",
        }}
      ></div>
      <div className="flex flex-row text-base font-normal">
        Copyright @ 2024 <p className="text-blue-500">Margdarshak</p>. All
        rights reserved.
      </div>
    </div>
  );
};
