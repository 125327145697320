import { useEffect, useState } from "react";
import logo from "../assets/margdarshakendra-logo.webp";
import axios from "axios";
import "../App.css";
import { useNavigate } from "react-router-dom";

export const CompleteProfile = () => {
  const navigate = useNavigate();

  const [userID, setUserID] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    mobile: "",
    whatsapp: "911234567890",
    dob: "",
    gender: "",
    login: "",
    pic_url: "",
    userID: "",
    address: "",
    placeID: "",
    pic_url: "",
    file: null,
  });
  const [errors, setErrors] = useState({});

  const fetchProfile = async () => {
    const userID = localStorage.getItem("userID");
    setUserID(userID);
    try {
      const response = await axios.post(
        "https://margda.in:7000/api/getuserdata",
        { userID }
      );
      if (response.status === 200) {
        setFormValues({ ...response.data.User[0] });
        const newDate = new Date(response.data.User[0].dob);
        const formattedDate = newDate.toISOString().split("T")[0];
        setFormValues((prevalue) => ({ ...prevalue, dob: formattedDate }));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          alert("User not found, Please login again");
          navigate("/login");
        }
      }
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormValues({ ...formValues, file: e.target.files[0] });
      const picUrl = URL.createObjectURL(file);
      setFormValues((prevValues) => ({
        ...prevValues,
        pic_url: picUrl,
      }));
    }
  };

  const handleOnInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const uploadProfilePic = async () => {
    const formData = new FormData();
    if (!formValues.file) {
      return alert("Select Profile Picture");
    }
    formData.append("image", formValues.file);

    try {
      const response = await fetch("https://margda.in:7000/api/upload", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();

        await updateProfile(data.fileUrl);
        setFormValues((prevValues) => ({
          ...prevValues,
          pic_url: data.fileUrl,
        }));
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Upload Profile Pic Error:", error);
      await updateProfile(
        "https://cdn.pixabay.com/photo/2016/04/01/10/11/avatar-1299805_1280.png"
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formValues.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!formValues.gender) {
      newErrors.gender = "Gender is required";
    }
    if (!formValues.dob) {
      newErrors.dob = "Date of Birth is required";
    }
    if (!formValues.whatsapp) {
      newErrors.whatsapp = "Whatsapp Number is required";
    }
    if (!formValues.address) {
      newErrors.address = "Address is required";
    }
    if (!formValues.placeID) {
      newErrors.placeID = "Pin Code is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await uploadProfilePic();
    } catch (error) {
      alert("Profile update failed. Please try again later.");
    }
  };

  const updateProfile = async (pic_url) => {
    const payload = {
      userID: userID,
      login: formValues.login,
      mobile: formValues.mobile,
      whatsappMobile: formValues.whatsapp,
      email: formValues.email,
      name: formValues.name,
      gender: formValues.gender,
      DOB: formValues.dob,
      address: formValues.address,
      pic_url: pic_url,
      placeID: formValues.placeID,
    };

    try {
      const response = await axios.put(
        "https://margda.in:7000/api/updateuser",
        payload
      );
      if (response.data.message === "User updated successfully") {
        localStorage.setItem("picUrl", pic_url);
        localStorage.setItem("whatsapp", formValues.whatsapp);
        localStorage.setItem("mobile", formValues.mobile);
        localStorage.setItem("email", formValues.email);
        localStorage.setItem("name", formValues.name);
        navigate("/dashboard");
        window.location.reload();
      } else {
        alert("Failed to update profile. Please try again.");
      }
    } catch (error) {
      alert("Failed to update profile. Please try again later.");
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-blue-100 via-white to-gray-100 flex justify-center items-center">
      <div className="bg-white p-8 w-full max-w-4xl shadow-lg rounded-xl">
        <div className=" top-4 left-4">
          <img src={logo} alt="Logo" className="w-36" />
        </div>
        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-800">
            Complete Your Profile
          </h2>
          <p className="text-gray-500 mt-2">
            Help us know you better by filling out the details below.
          </p>
        </div>

        {/* Form */}
        <div className="bg-white p-6 shadow-sm rounded-lg">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Profile Picture Section */}
            <div className="flex flex-col items-center">
              <img
                src={
                  formValues.pic_url ||
                  "https://cdn.pixabay.com/photo/2016/04/01/10/11/avatar-1299805_1280.png"
                }
                alt="Profile Picture"
                className="w-24 h-24 rounded-full border-4 border-blue-500 shadow-md mb-4"
              />
              <label
                htmlFor="profilePic"
                className="text-sm text-blue-600 cursor-pointer hover:underline"
              >
                Upload Profile Picture
              </label>
              <input
                type="file"
                onChange={handleProfilePicChange}
                accept="image/*"
                id="profilePic"
                className="hidden"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="name" className="font-bold p-1 text-base">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  onChange={handleOnInputChange}
                  value={formValues.name}
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                )}
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="gender" className="font-bold p-1 text-base">
                  Gender
                </label>
                <select
                  name="gender"
                  onChange={handleOnInputChange}
                  value={formValues.gender}
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                >
                  <option value="">Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </select>
                {errors.gender && (
                  <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
                )}
              </div>
            </div>

            {/* Mobile, WhatsApp, Email Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="mobile" className="font-bold p-1 text-base">
                  Mobile
                </label>
                <input
                  type="text"
                  value={formValues.mobile}
                  placeholder="Mobile"
                  disabled
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="whatsapp" className="font-bold p-1 text-base">
                  WhatsApp Number
                </label>
                <input
                  type="text"
                  name="whatsapp"
                  value={formValues.whatsapp}
                  placeholder="Enter WhatsApp Number"
                  onChange={handleOnInputChange}
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
                {errors.whatsapp && (
                  <p className="text-red-500 text-sm mt-1">{errors.whatsapp}</p>
                )}
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="email" className="font-bold p-1 text-base">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  disabled
                  value={formValues.email}
                  onChange={handleOnInputChange}
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
              </div>
            </div>

            {/* Date of Birth, Address, Pin Code */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="dob" className="font-bold p-1 text-base">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formValues.dob}
                  onChange={handleOnInputChange}
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
                {errors.dob && (
                  <p className="text-red-500 text-sm mt-1">{errors.dob}</p>
                )}
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="address" className="font-bold p-1 text-base">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={formValues.address}
                  onChange={handleOnInputChange}
                  placeholder="Enter your address"
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
                {errors.address && (
                  <p className="text-red-500 text-sm mt-1">{errors.address}</p>
                )}
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="placeID" className="font-bold p-1 text-base">
                  Pin Code
                </label>
                <input
                  type="text"
                  name="placeID"
                  value={formValues.placeID}
                  onChange={handleOnInputChange}
                  placeholder="Pin Code"
                  className="px-3  w-[90%] py-2 border border-gray-400 rounded font-light focus:ring-blue-500 text-base focus:border-blue-500 "
                />
                {errors.placeID && (
                  <p className="text-red-500 text-sm mt-1">{errors.placeID}</p>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-8 text-center">
              <button
                type="submit"
                className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg font-semibold text-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition ease-in-out duration-200"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
