import { useEffect, useState } from "react";
import io from "socket.io-client";
import Loader from "../../Components/Loader";

const SOCKET_SERVER_URL = "https://margda.in:3000";

export const AddWhatsapp = () => {
  const [instanceId, setInstanceId] = useState(null);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading spinner
  const [name, setName] = useState(null);
  const [getBtnText, setGetButtonText] = useState("Get Qr Code");
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    fetchProfiles();
  }, []);

  const startSocket = (userId) => {
    const socketInstance = io(SOCKET_SERVER_URL);

    socketInstance.emit("registerUser", userId);
    socketInstance.on("receiveqrcode", (msg) => {
      setQrCodeSrc(msg.qrcode);
    });

    socketInstance.on("clientallready", (msg) => {
      setName(`Welcome Again : ${msg.name}`);
      setInstanceId(msg.instanceId);
      setQrCodeSrc(null);
      //   const tempProfile5 = tempProfiles.filter(
      //     (item) => String(item.phoneNumber) !== String(msg.phoneNumber)
      //   );
      //   console.log(tempProfile5);
      //   setTempProfiles(tempProfile5);
    });

    socketInstance.on("clientready", (msg) => {
      setName(`You are connected as : ${msg.name}`);
      setInstanceId(msg.instanceId);
      setGetButtonText("Get New Qr Code");
      setQrCodeSrc(null);
    });
  };

  const userID = localStorage.getItem("userID");

  const getInstance = async () => {
    setLoading(true);
    startSocket(userID);
    const response = await fetch(
      "https://margda.in:3000/api/margda/scan-whatsapp/getinstance",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      //   setInstanceId(data.);
    }
    setLoading(false);
    console.log(data);
  };

  const fetchProfiles = async () => {
    const response = await fetch(
      "https://margda.in:3000/api/margda/scan-whatsapp/getprofiles",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      setProfiles(data.Profiles);
    }
  };

  const getQrCode = () => {
    getInstance();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {loading && (
        <div className="flex flex-col items-center">
          <Loader />
          <p className="text-gray-600 mt-4">Fetching data, please wait...</p>
        </div>
      )}
      {!loading && (
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
          {/* QR Code Display */}
          {qrCodeSrc ? (
            <img
              src={qrCodeSrc}
              alt="QR Code"
              className="w-64 h-64 mx-auto mb-4"
            />
          ) : (
            <p className="text-gray-500 mb-4 font-normal font-mono text-base">
              No QR Code available
            </p>
          )}

          {/* Button */}
          <button
            onClick={getQrCode}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 font-normal font-mono text-xl"
          >
            {getBtnText}
          </button>

          {/* Instance ID */}
          <div className="mt-6 text-left font-normal font-mono text-base">
            <span className="font-semibold text-gray-700">Instance ID:</span>
            <p className="mt-1 text-gray-800">
              {instanceId ? instanceId : "Not fetched yet"}
            </p>
          </div>

          {/* Name */}
          {name && (
            <div className="mt-4 text-left">
              <span className="font-semibold text-gray-700">Name:</span>
              <p className="mt-1 text-gray-800">{name}</p>
            </div>
          )}
          <div className="flex flex-row flex-wrap p-4">
            {profiles.length > 0 &&
              profiles.map((item, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center text-center border border-gray-200"
                >
                  {/* Profile Picture */}
                  <img
                    src={
                      item.pic
                        ? item.pic
                        : "https://static-00.iconduck.com/assets.00/whatsapp-icon-256x256-1ysn3lnm.png"
                    }
                    alt={item.name || "Profile Pic"} // Optional: Provide an alt attribute for accessibility
                    onError={(e) => {
                      e.target.src =
                        "https://static-00.iconduck.com/assets.00/whatsapp-icon-256x256-1ysn3lnm.png";
                    }}
                    className="w-20 h-20 rounded-full object-cover border border-gray-300"
                  />

                  {/* Name */}
                  <div className="mt-4 font-semibold text-gray-800 text-lg">
                    {item.name}
                  </div>

                  {/* Phone Number */}
                  <div className="text-gray-600 text-sm mt-1">
                    {item.mobile}
                  </div>

                  {/* Status */}
                  <div
                    className={`mt-3 px-4 py-1 rounded-full text-sm font-medium ${
                      item.active
                        ? "bg-green-100 text-green-600"
                        : "bg-red-100 text-red-600"
                    }`}
                  >
                    {item.active ? "Active" : "Re-login"}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
