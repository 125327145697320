import { useEffect, useState } from "react";
import Papa from "papaparse";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import Loader from "../Components/Loader";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";

export const AllData = () => {
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [addLead, setAddLead] = useState(false);
  const [updateLead, setUpdateLead] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showAddLead, setShowAddLead] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [userID, setUserID] = useState(null);
  const [message, setMessage] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [editDataId, setEditDataId] = useState(null);

  const handleMobileChange = (value) => {
    setMobile(value);
  };

  const handleWhatsappChange = (value) => {
    setWhatsapp(value);
  };

  const handleSubmit = async (e) => {
    if (!email) {
      return setMessage("Email required");
    }
    const userID = localStorage.getItem("userID");
    let apiUrl;
    let method;
    if (addLead) {
      apiUrl = "https://margda.in:7000/api/addlead";
      method = "POST";
    } else if (updateLead) {
      apiUrl = "https://margda.in:7000/api/margda.org/edit-data";
      method = "PUT";
    }
    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          whatsapp,
          gender,
          userID,
          dataID: editDataId,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setEmail("");
        setMobile("");
        setWhatsapp("");
        setName("");
        if (updateLead) {
          setMessage("Data updated successfully!");
          console.log(result.data);
          setLeads((prevLeads) =>
            prevLeads.map((lead) =>
              lead.dataID === editDataId ? { ...lead, ...result.data } : lead
            )
          );
          setCurrentData((prevLeads) =>
            prevLeads.map((lead) =>
              lead.dataID === editDataId ? { ...lead, ...result.data } : lead
            )
          );
        } else if (addLead) {
          setLeads((prevLeads) => [...prevLeads, result.data]);
          setCurrentData((prevLeads) => [...prevLeads, result.data]);
          setMessage("Data added successfully!");
        }
        setShowAddLead(false);
      } else if (response.status === 409) {
        setMessage("Email already present");
      } else {
        if (updateLead) {
          setMessage("Failed to update data. Try again!");
        } else if (addLead) {
          setMessage("Failed to add data. Try again!");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Something went wrong. Please try again.");
    }
  };

  const expectedHeaders = ["name", "mobile", "whatsapp", "email", "gender"];

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    const userId = localStorage.getItem("userID");
    setUserID(userId);
    if (!userId) return setLeads([]);
    setLoading(true);

    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda.org/get-all-data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userID: userId }),
        }
      );

      setLoading(false);
      if (!response.ok) throw new Error("Failed to fetch leads");
      const data = await response.json();
      const sortData = data.Data.sort((a, b) => a.dataID - b.dataID);
      setLeads(sortData);
      setTotalPages(Math.ceil(data.Data.length / entriesToShow));
      const addHiddenData = await Promise.all(
        sortData.map(async (item) => {
          if (item.euser == userId) {
            return item;
          }
          item.mobile = item.mobile
            ? `${item.mobile.slice(0, 2)}` + "**********"
            : "N/A";
          item.email = item.email
            ? `${item.email.slice(0, 5)}` +
              "***********" +
              `${item.email.slice(item.email.length - 3, item.email.length)}`
            : "N/A";
          item.whatsapp = item.whatsapp
            ? `${item.whatsapp.slice(0, 2)}` + "**********"
            : "N/A";
          return item;
        })
      );
      setLoading(false);

      setCurrentData(addHiddenData);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = filteredLeads.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );

    setCurrentData(data);
    setTotalPages(Math.ceil(filteredLeads.length / entriesToShow));
  }, [entriesToShow, page, searchTerm]);

  useEffect(() => {
    setPage(1);
  }, [entriesToShow]);

  const downloadSample = () => {
    const data =
      "name,mobile,whatsapp,email,gender\nJohn Doe,911234567890,911234567890,john@example.com,male\nJahn Doe,911234567890,911234567890,jahn@example.com,female\nJohn Doe,911234567890,911234567890,johndeep@example.com,other"; // Example data (e.g., CSV content)
    const blob = new Blob([data], { type: "text/csv" }); // Create a Blob object with the file content
    const url = URL.createObjectURL(blob); // Create a URL for the Blob

    const a = document.createElement("a"); // Create an anchor element
    a.href = url; // Set the href to the Blob URL
    a.download = "contacts.csv"; // Set the download filename
    a.click(); // Programmatically click the link to start the download

    URL.revokeObjectURL(url); // Clean up the Blob URL
  };

  const handleCheckboxChange = (index) => {
    const selectedLead = currentData[index];
    // if (
    //   !selectedLead.mobile ||
    //   !selectedLead.whatsapp ||
    //   !selectedLead.email ||
    //   selectedLead.mobile == "N/A" ||
    //   selectedLead.whatsapp == "N/A" ||
    //   selectedLead.email == "N/A"
    // ) {
    //   alert(
    //     "cannot select a data that does not have email, mobile or whatsapp"
    //   );
    //   return;
    // }
    // setSelectedLeads((prevSelectedLeads) =>
    //   prevSelectedLeads.includes(selectedLead)
    //     ? prevSelectedLeads.filter((lead) => lead !== selectedLead)
    //     : () => {
    //         if (
    //           !selectedLead.mobile ||
    //           !selectedLead.whatsapp ||
    //           !selectedLead.email ||
    //           selectedLead.mobile == "N/A" ||
    //           selectedLead.whatsapp == "N/A" ||
    //           selectedLead.email == "N/A"
    //         ) {
    //           alert(
    //             "cannot select a data that does not have email, mobile or whatsapp"
    //           );
    //           return;
    //         }
    //         return [...prevSelectedLeads, selectedLead];
    //       }
    // );
    setSelectedLeads((prevSelectedLeads) => {
      if (prevSelectedLeads.includes(selectedLead)) {
        return prevSelectedLeads.filter((lead) => lead !== selectedLead);
      } else {
        if (
          !selectedLead.mobile ||
          !selectedLead.whatsapp ||
          !selectedLead.email ||
          selectedLead.mobile == "N/A" ||
          selectedLead.whatsapp == "N/A" ||
          selectedLead.email == "N/A"
        ) {
          alert(
            "cannot select a data that does not have email, mobile or whatsapp"
          );
          return [...prevSelectedLeads];
        }
        return [...prevSelectedLeads, selectedLead];
      }
    });
  };

  const handleSelectAll = (isChecked) => {
    setSelectedLeads(isChecked ? [...leads] : []);
  };

  const handleAddLead = () => {
    setMessage("");
    setShowAddLead(true);
    setAddLead(true);
    setEmail("");
    setName("");
    setWhatsapp("");
    setMobile("");
    setGender("");
  };

  const filteredLeads = leads.filter(
    (lead) =>
      (lead.name &&
        lead.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.email &&
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.mobile && lead.mobile.includes(searchTerm))
  );

  const handleEditLead = async (dataID) => {
    setUpdateLead(true);
    setShowAddLead(true);
    setMessage("");
    setEditDataId(dataID);
    const data = leads.filter((item) => item.dataID == dataID);
    setEmail(data[0].email);
    setName(data[0].name);
    setWhatsapp(data[0].whatsapp);
    setMobile(data[0].mobile);
    setGender(data[0].gender);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        skipEmptyLines: true, // Skip empty lines
        complete: (results) => {
          const { data, meta } = results;
          const fileHeaders = meta.fields;
          const lowerCaseFileHeaders = fileHeaders.map((header) =>
            header.toLowerCase()
          );
          const lowerCaseExpectedHeaders = expectedHeaders.map((header) =>
            header.toLowerCase()
          );
          if (
            JSON.stringify(lowerCaseFileHeaders) !==
            JSON.stringify(lowerCaseExpectedHeaders)
          ) {
            alert(`Invalid columns. Expected: ${expectedHeaders.join(", ")}`);
            setTableData([]);
            setHeaders([]);
            return;
          }

          setHeaders(fileHeaders);
          setTableData(data);
          setShowTable(true);
        },
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    if (searchTerm !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchTerm]);

  const handleAddLeadFromCsv = async () => {
    const userId = localStorage.getItem("userID");
    setShowTable(false);
    setLoading(true);
    for (let i = 0; i < tableData.length; i++) {
      const entry = tableData[i];
      try {
        await delay(i * 50);
        const lead = {
          name: entry.name,
          email: entry.email,
          mobile: entry.mobile,
          whatsapp: entry.whatsapp,
          gender:
            entry.gender.toLowerCase() === "male"
              ? "M"
              : entry.gender.toLowerCase() === "female"
              ? "F"
              : "O",
        };
        const response = await fetch("https://margda.in:7000/api/addlead", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: entry.name,
            mobile: entry.mobile,
            email: entry.email,
            whatsapp: entry.whatsapp,
            gender:
              entry.gender.toLowerCase() === "male"
                ? "M"
                : entry.gender.toLowerCase() === "female"
                ? "F"
                : "O",
            userID: userId,
          }),
        });
        const result = await response.json();
        if (response.ok) {
        }
      } catch (error) {
        console.error(error);
        toast.error("unknown error occured");
      }
    }
    setLoading(false);
    toast.success("Data added");
    await fetchLeads();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleShortlist = async () => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to shortlist");
    }
    setLoading(true);
    const userId = localStorage.getItem("userID");
    for (let index = 0; index < selectedLeads.length; index++) {
      const lead = selectedLeads[index];
      if (
        !lead.mobile ||
        !lead.whatsapp ||
        !lead.email ||
        lead.mobile == "N/A" ||
        lead.whatsapp == "N/A" ||
        lead.email == "N/A"
      ) {
        continue;
      }

      try {
        await delay(index * 10);
        const response = await fetch(
          "https://margda.in:7000/api/margda.org/shortlist",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userID: lead.userID,
              dataID: lead.dataID,
              euser: userId,
            }),
          }
        );
      } catch (error) {
        console.log(error);
        return toast.error("unknown error occured");
      }
    }
    setLoading(false);
    toast.success("Successfully shortlisted");
  };

  return (
    <div className="flex flex-col bg-gray-200 shadow-lg mx-auto p-6">
      <div className="flex justify-end flex-wrap w-full space-x-1 mb-4">
        <button
          onClick={handleShortlist}
          className="bg-blue-500 text-white px-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
        >
          Shortlist
        </button>
        <button
          onClick={handleAddLead}
          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 font-bold font-body text-xl"
        >
          +
        </button>
        {/* <div> */}
        <label
          htmlFor="uploadCsv"
          className="bg-blue-500 py-2 text-white px-2 text-justify text-center rounded hover:bg-blue-600 font-normal font-body text-base"
        >
          Upload CSV
        </label>
        <button
          onClick={downloadSample}
          className="bg-blue-500 text-white px-2 rounded hover:bg-blue-600 font-normal font-mono text-base"
        >
          Sample Csv
        </button>
      </div>
      <div>
        <input
          type="file"
          id="uploadCsv"
          accept=".csv"
          onChange={handleFileUpload}
          className="hidden"
        />
      </div>
      {loading ? <Loader /> : <></>}
      {tableData.length > 0 && showTable && (
        <div class="fixed inset-0 flex items-center justify-center z-50">
          <div class=" border border-gray-500 relative bg-white shadow-lg rounded-lg overflow-x-auto overflow-y-auto max-h-[700px] w-3/4 p-6">
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => setShowTable(false)}
                className="bg-green-500 text-white px-4 py-2 mr-3 rounded-lg hover:bg-green-600 mb-2"
              >
                Cancel
              </button>
              <button
                onClick={handleAddLeadFromCsv}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 mb-2"
              >
                Add all leads
              </button>
            </div>{" "}
            <table className="min-w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-800 text-white text-center">
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="py-3 px-4 text-justify uppercase font-semibold text-sm"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="border-t hover:bg-gray-100 cursor-pointer border"
                  >
                    {headers.map((header, colIndex) => (
                      <td
                        key={colIndex}
                        className="py-[9px] px-4 text-justify text-xl font-sans font-normal min-w-50"
                      >
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="bg-white px-4 border-1 rounded shadow-lg">
        <div className="flex justify-between items-center my-2 w-[100%]">
          <div>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              Show Entries:
            </label>
            <select
              id="entries"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(Number(e.target.value))}
              className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="items-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
            />
          </div>
        </div>

        {currentData.length === 0 ? (
          <div className="text-center text-gray-600  p-20">No Data found</div>
        ) : (
          <div>
            <div className="rounded-lg shadow-lg max-h-[600px] overflow-y-scroll min-h-[400px]">
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-800 text-white text-center">
                    <th className="py-3 px-4">
                      <input
                        type="checkbox"
                        checked={selectedLeads.length === leads.length}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Action
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Data
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Logs
                    </th>
                    <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.slice(0, entriesToShow).map((lead, index) => (
                    <tr
                      key={index}
                      className="border-t hover:bg-gray-100 cursor-pointer border"
                      onClick={(e) => {
                        // Prevent toggle when clicking directly on the checkbox
                        if (e.target.type !== "checkbox") {
                          handleCheckboxChange(index);
                        }
                      }}
                    >
                      <td className="py-3 px-4">
                        <input
                          type="checkbox"
                          checked={selectedLeads.includes(lead)}
                          className="cursor-pointer"
                          onChange={() => handleCheckboxChange(index)}
                          onClick={(e) => e.stopPropagation()} // Prevent row click
                        />
                      </td>
                      <td className="px-4 text-justify font-sans font-normal ">
                        {lead.euser == userID && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              handleEditLead(lead.dataID);
                            }}
                          >
                            <FaEdit className="cursor-pointer text-blue-500 ml-2" />
                          </button>
                        )}

                        <button
                          className="cursor-pointer text-blue-500 ml-2"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            // handleDeleteLead(lead.dataID);
                          }}
                        >
                          <FaTrash />
                        </button>
                        <button>
                          <FaEye
                            className="cursor-pointer text-blue-500 ml-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              // Add view functionality here
                            }}
                          />
                        </button>
                        {lead.userID && (
                          <span className="text-xl text-justify ml-2 font-sans font-normal">
                            U
                          </span>
                        )}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                        <div className="flex flex-row items-center">
                          <div>
                            {" "}
                            <img
                              src={
                                lead.pic_url
                                  ? lead.pic_url
                                  : lead.gender === "M"
                                  ? "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                  : lead.gender === "F"
                                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmvJ14anYdkKq4d0LRhRh0a_u_Kh6DUQGHsQ&s"
                                  : lead.gender === null
                                  ? "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                                  : "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                              }
                              alt="img"
                              className="w-20 h-20 rounded-full border-1 shadow-md mb-4"
                            />
                          </div>
                          <div>
                            {lead.name || "N/A"}
                            {<br />}
                            <span className="flex flex-row m-0 p-0 items-center">
                              <IoIosCall className="mr-1" />
                              {lead.mobile || "N/A"}
                            </span>

                            <span className="flex flex-row m-0 p-0 items-center">
                              {<FaWhatsapp className="mr-1" />}
                              {lead.whatsapp || "N/A"}
                            </span>

                            <span className="flex flex-row m-0 p-0 items-center">
                              {<TfiEmail className="mr-1" />}
                              {lead.email}
                            </span>
                            {lead.gender === "M"
                              ? "Male"
                              : lead.gender === "F"
                              ? "Female"
                              : lead.gender === null
                              ? "Not Selected"
                              : lead.gender === "O"
                              ? "Others"
                              : "N/A"}
                          </div>
                        </div>
                      </td>
                      <td className="py-[9px] px-4 text-justify text-xl font-sans font-normal min-w-50">
                        {"logs"}
                      </td>
                      <td className="py-[9px] px-4 text-justify text-xl font-sans font-normal min-w-50">
                        {"remarks"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row justify-between my-3">
              <div className="flex flex-row">
                {page === totalPages ? (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {filteredLeads.length} of {filteredLeads.length} entries{" "}
                  </div>
                ) : (
                  <div className="text-base font-light ">
                    Showing {(page - 1) * entriesToShow + 1} to{" "}
                    {page * entriesToShow} of {filteredLeads.length} entries{" "}
                  </div>
                )}
                {
                  <div className="font-light text-base ml-2">
                    {showFilteredFrom &&
                      `(filtered from ${leads.length} entries)`}
                  </div>
                }
              </div>
              <div className="flex justify-center space-x-2">
                {/* Previous Button */}
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Previous
                </button>

                {/* Page Numbers */}
                {totalPages <= 5 ? (
                  // Show all pages if totalPages is less than or equal to 5
                  Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === index + 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {/* First page */}
                    <button
                      onClick={() => handlePageChange(1)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === 1 ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      1
                    </button>

                    {/* Second page */}
                    {page === 2 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === 2 ? "bg-slate-500 text-white" : ""
                        }`}
                      >
                        2
                      </button>
                    )}

                    {/* Ellipsis */}
                    {page > 3 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}

                    {/* Current page */}
                    {page > 2 && page < totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(page)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white bg-slate-500 text-white`}
                      >
                        {page}
                      </button>
                    )}

                    {/* Ellipsis for remaining pages */}
                    {page < totalPages - 2 && (
                      <span className="px-3 py-2 font-normal text-sm">...</span>
                    )}
                    {page === totalPages - 1 && (
                      <button
                        onClick={() => handlePageChange(2)}
                        className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                          page === totalPages - 1
                            ? "bg-slate-500 text-white"
                            : ""
                        }`}
                      >
                        {totalPages - 1}
                      </button>
                    )}

                    {/* Last page */}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                        page === totalPages ? "bg-slate-500 text-white" : ""
                      }`}
                    >
                      {totalPages}
                    </button>
                  </>
                )}

                {/* Next Button */}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {showAddLead && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 sm:p-7 rounded-lg shadow-lg w-full max-w-[90%] sm:max-w-[70%] lg:max-w-[60%] -mt-32">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-700 text-center mb-6">
              Add New Lead
            </h2>
            <div className="space-y-6">
              {/* Input Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-gray-600 text-sm sm:text-base mb-2 font-black"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="px-3 py-2 border border-gray-400 rounded font-light w-full focus:ring-blue-500 text-base focus:border-blue-500"
                    placeholder="Enter name"
                  />
                </div>

                <div className="flex flex-col items-start w-full">
                  <label
                    htmlFor=""
                    className="block text-gray-600 text-sm sm:text-base mb-2 font-black"
                  >
                    Mobile No.
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={mobile}
                    onChange={handleMobileChange}
                    placeholder="Mobile"
                    inputStyle={{
                      width: "100%",
                      height: "42px",
                      paddingLeft: "58px",
                      border: "1px solid #9ca3af",
                      borderRadius: "0.25rem",
                      textAlign: "left",
                    }}
                    buttonStyle={{
                      borderRadius: "0.25rem",
                      height: "42px",
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #9ca3af",
                    }}
                  />
                </div>

                <div className="flex flex-col items-start w-full">
                  <label
                    htmlFor=""
                    className="block text-gray-600 text-sm sm:text-base mb-2 font-black"
                  >
                    Whatsapp No.
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={whatsapp}
                    onChange={handleWhatsappChange}
                    placeholder="Whatsapp"
                    inputStyle={{
                      width: "100%",
                      height: "42px",
                      paddingLeft: "58px",
                      border: "1px solid #9ca3af",
                      borderRadius: "0.25rem",
                      textAlign: "left",
                    }}
                    buttonStyle={{
                      borderRadius: "0.25rem",
                      height: "42px",
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #9ca3af",
                    }}
                  />
                </div>
              </div>

              {/* Email and Gender */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-gray-600 text-sm sm:text-base mb-2 font-black"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-3 py-2 border border-gray-400 rounded font-light w-full focus:ring-blue-500 text-base focus:border-blue-500"
                    placeholder="Enter email"
                  />
                </div>
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-gray-600 text-sm sm:text-base mb-2 font-black"
                    htmlFor="gender"
                  >
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="px-3 py-2 border border-gray-400 rounded font-light w-full focus:ring-blue-500 text-base focus:border-blue-500"
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => {
                    setShowAddLead(false);
                    setAddLead(false);
                    setUpdateLead(false);
                  }}
                  className="w-1/3 sm:w-1/4 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="w-1/3 sm:w-1/4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  {addLead ? "Add Data" : updateLead ? "Update Data" : "Add"}
                </button>
              </div>

              {/* Message */}
              {message && (
                <div className="mt-4 text-center text-sm sm:text-base text-green-500">
                  {message}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};
