import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import ForgetPasswordOtpDialog from "./ForgetPasswordOtp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

export const ForgetPassword = () => {
  const [imageHeight, setImageHeight] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const imageRef = useRef(null); // Ref to get image element
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Update form height based on image height Terms of Use and Privacy Policy.
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, []);

  const sendOTP = async () => {
    if (!phoneNumber) {
      toast.error("Please enter your mobile number.");
      return;
    }
    const apiUrl =
      "https://margda.in:7000/api/android/forget-password-send-otp"; // API URL

    try {
      const response = await axios.post(apiUrl, {
        mobile: phoneNumber,
      });

      console.log("Response from OTP API:", response.data);

      if (response.status === 200) {
        toast.success("OTP sent successfully");
        setOtpDialogOpen(true); // Open OTP dialog here
      } else if (response.status === 400) {
        alert("Phone number already exists, try to forget password");
      } else {
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      if (error.status === 400) {
        console.log(error.response.data.message);
        return toast.error(error.response.data.message);
      }
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP. Please try again later.");
    }
  };

  const verifyOtp = async (inputOtp) => {
    console.log(inputOtp);
    const apiUrl =
      "https://margda.in:7000/api/android/forget-password-verify-otp";
    try {
      const response = await axios.post(apiUrl, {
        mobile: phoneNumber,
        otp: inputOtp,
      });

      console.log("Response from OTP API:", response.data);

      if (response.status === 200) {
        toast.success(
          "OTP verified credentials will be sent to your email and mobile number"
        );
        setOtpDialogOpen(false); // Open OTP dialog here
        // navigate("/login");
      } else if (response.status === 400) {
        //   alert("Phone number already exists, try to forget password");
      } else {
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      if (error.status === 400) {
        console.log(error.response.data.message);
        return toast.error(error.response.data.message);
      }
      console.error("Error in verifing OTP:", error);
      toast.error("Error verifing OTP. Please try again later.");
    }
  };

  return (
    <>
      <div className="flex min-h-screen justify-center items-center">
        <div ref={imageRef} className="mt-30 w-1/2 hidden sm:flex">
          <img
            src="https://margdarshak.org/img/skill%20rehan.png"
            alt="Illustration"
            className="block"
          />
        </div>

        {/* Form Section */}
        <div
          className="flex flex-col justify-between p-10"
          style={{
            width: "550px",
            marginTop: "-50px",
          }}
        >
          <div className="flex items-center mb-4">
            <img
              src="https://margdarshak.in/img/Mlogo.png"
              alt=""
              className="w-12"
            />
            <h2 className="text-xl font-bold ml-4 mb-6 mt-3">
              Verify phone number to forget password
            </h2>
          </div>

          {/* Phone Input with OTP button inside */}
          <div className="relative mb-5">
            <div className="flex">
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                placeholder="Mobile"
                inputStyle={{
                  width: "100%",
                  height: "50px",
                  paddingLeft: "58px", // Leave space for the flag and dialing code
                  border: "1px solid #d1d5db",
                  borderRadius: "0.5rem 0 0 0.5rem", // Rounded only on the left side
                  textAlign: "left",
                }}
                buttonStyle={{
                  borderRadius: "0.5rem 0 0 0.5rem", // To ensure flag is inside the input and the border looks clean
                  height: "50px", // Match height of input
                  display: "flex", // Use flex to ensure the icon shows properly
                  alignItems: "center",
                }}
              />
              <button
                className="px-8 h-12 text-white rounded-r-lg font-semibold"
                style={{ backgroundColor: "#0d5a3f" }}
                onClick={sendOTP} // Call sendOTP on button click
              >
                OTP
              </button>
            </div>
          </div>

          {otpDialogOpen && (
            <ForgetPasswordOtpDialog
              open={otpDialogOpen}
              onClose={() => setOtpDialogOpen(false)}
              onSubmit={verifyOtp} // Pass the verifyOtp function here
            />
          )}

          {/* Toast Notification Container */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
