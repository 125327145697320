import React, { useEffect, useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import {
  MdOutlineDashboard,
  MdOutlineMessage,
  MdBusinessCenter,
  MdOutlineCurrencyRupee,
} from "react-icons/md";
import {
  RiSettings4Line,
  RiLogoutBoxLine,
  RiCalendarScheduleLine,
  RiLockPasswordLine,
} from "react-icons/ri";
import { CiWallet } from "react-icons/ci";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaC, FaWhatsapp, FaSheetPlastic } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineHeart, AiOutlineGlobal } from "react-icons/ai";
import { FaSms, FaDatabase, FaVideo, FaCoins } from "react-icons/fa";
import { IoIosCall, IoIosArrowForward } from "react-icons/io";
import { SiWhatsapp, SiGoogleadsense } from "react-icons/si";
import { CgProfile } from "react-icons/cg";
import { BsMicrosoftTeams } from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import { TbBrandZoom } from "react-icons/tb";
import { CiVideoOn } from "react-icons/ci";
import { Link, Route, Routes } from "react-router-dom";
import { AllLeads } from "./AllLeads";
import { Credentials } from "./Main/Credentials";
import SidebarLogo from "../assets/margdarshakendra-logo.webp";
import Admin from "./Admin";
import { BsQrCode } from "react-icons/bs";
import { CompleteProfile } from "../Components/CompleteProfile";
import { ScheduleTask } from "./ScheduleTask";
import { ValidEmails } from "./ValidateEmail";
import { useNavigate } from "react-router-dom";
import { TrackEmployeeMap } from "./TrackEmployeeMap";
import { AddGeofence } from "./AddGeofence";
import { AddWhatsapp } from "./Main/AddWhatsapp";
import MainDashboad from "./MainDashboad";
import AttendenceQrCode from "./AttendenceQrCode";
import { Users } from "./Users";
import { AllData } from "./AllData";
import { CallReport } from "./Report/CallReport";
import { WhatsAppReport } from "./Report/WhatsappReport";
import { TemplatesList } from "./Template/TemplatesList";
import { AddFooter } from "./Template/AddFooter";
import { AddTemplate } from "./Template/AddTemplate";
import { SMSReport } from "./Report/SmsReport";
import { FooterList } from "./Template/FooterList";
import { GoogleMeetReport } from "./MeetingsReport/GoogleMeet";
import { MicrosoftTeamReport } from "./MeetingsReport/MicrosoftTeam";
import { ZoomReport } from "./MeetingsReport/Zoom";
import { EditTemplate } from "./Template/EditTemplate";
import { Payment } from "./Payment";

export const Sidebar = () => {
  const [name, setName] = useState(null);
  const [profileComplete, setProfileComplete] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [profileShow, setProfileShow] = useState(false);
  const [reportShow, setReportShow] = useState(false);
  const [meetingReportShow, setMeetingReportShow] = useState(false);
  const [templateShow, setTemplateShow] = useState(false);
  const [userID, setUserId] = useState(null);
  const menus = [
    // { name: "Google Data", link: "googledata", icon: BsDatabaseUp },
    {
      name: "Schedule Task",
      link: "scheduletask",
      icon: RiCalendarScheduleLine,
    },
    {
      name: "track-employee",
      link: "track-employee",
      icon: AiOutlineGlobal,
    },
    {
      name: "add-geofence",
      link: "add-geofence",
      icon: AiOutlineGlobal,
    },
    {
      name: "Attendence",
      link: "attendence",
      icon: BsQrCode,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const userId = localStorage.getItem("userID");
    setUserId(userId);
    const name = localStorage.getItem("name");
    setName(name);
    if (!userId) {
      navigate("/login");
    }
    const checkProfile = localStorage.getItem("picUrl");
    if (!checkProfile || checkProfile == "null" || checkProfile === undefined) {
      setProfileComplete(false);
    } else {
      setProfileComplete(true);
      setProfilePicUrl(checkProfile);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("userID");
    localStorage.removeItem("picUrl");
    localStorage.removeItem("whatsap");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    navigate("/login");
  };

  const [open, setOpen] = useState(true); // Track sidebar open/closed state

  return (
    <section className="flex gap-6">
      {profileComplete ? (
        <>
          <div
            className={`bg-gradient-to-br from-gray-900 to-gray-800 h-screen fixed overflow-scroll ${
              open ? "w-60" : "w-16"
            } duration-500 text-gray-100 px-4`}
          >
            <div className="py-3 flex justify-between items-center">
              {open && (
                <div className="flex w-full items-center">
                  <img
                    src={SidebarLogo}
                    className="pr-5 py-2 w-full"
                    alt="Sidebar-logo"
                  />
                </div>
              )}
              <HiMenuAlt3
                size={26}
                className="cursor-pointer"
                onClick={() => setOpen(!open)}
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 relative">
              <div
                className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-2 pl-1"
                onClick={() => setProfileShow(!profileShow)}
              >
                <div
                  style={{
                    position: "relative",
                    width: "50px",
                    height: "50px",
                    overflow: "hidden",
                    borderRadius: "64px",
                  }}
                >
                  <img
                    src={profilePicUrl}
                    alt="Cover Image"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      top: "0",
                      left: "0",
                      width: "50px",
                      height: "50px",
                      borderRadius: "64px",
                      zIndex: "2",
                    }}
                  />
                </div>
                <div className={`text-base ${open ? "ml-4" : "ml-0"}`}>
                  {name ? name : ""}
                </div>
                <div className="flex justify-end" style={{ width: "35%" }}>
                  <IoIosArrowForward
                    className={`text-xl transform duration-300 ${
                      profileShow ? "rotate-90" : "rotate-0"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col items-start ml-[20px] transition-all duration-500 overflow-hidden ${
                  profileShow && open
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <Link to={"profile"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <CgProfile className="mr-4" />
                    Profile
                  </div>
                </Link>
                <Link to={"credentials"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <RiLockPasswordLine className="mr-4" />
                    Credentials
                  </div>
                </Link>
                <Link to={"add-whatsapp"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaWhatsapp className="mr-4" />
                    Scan Whatsapp
                  </div>
                </Link>
              </div>
              <div className="flex flex-col ">
                <Link
                  className={`flex flex-row items-center hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md ${
                    !open ? "w-9 h-9 pl-3" : "p-1 px-3"
                  }`}
                >
                  <MdOutlineMessage
                    className={`mr-2 ${!open ? "text-xl" : ""}`}
                  />
                  <div>{open ? "Messages" : ""}</div>
                </Link>
                <div
                  className={`flex flex-row items-center pl-3 ${
                    !open ? "h-9 w-9" : ""
                  }`}
                >
                  <MdBusinessCenter
                    className={`mr-2  ${!open ? "text-xl" : ""}`}
                  />
                  {open ? "Business: ₹ 0.00" : ""}
                </div>
                <div
                  className={`flex flex-row items-center pl-3  ${
                    !open ? "h-9 w-9" : ""
                  }`}
                >
                  <CiWallet className={`mr-2  ${!open ? "text-xl" : ""}`} />
                  {open ? "Wallet: ₹ 0.00" : ""}
                </div>
                <div
                  className={`flex flex-row items-center pl-3 ${
                    !open ? "h-9 w-9" : ""
                  } `}
                >
                  <FaCoins className={`mr-2  ${!open ? "text-xl" : ""}`} />
                  {open ? "Account: ₹ 0.00" : ""}
                </div>
                <div
                  className={`flex flex-row items-center pl-3 ${
                    !open ? "h-9 w-9" : ""
                  } `}
                >
                  <MdOutlineCurrencyRupee
                    className={`mr-2  ${!open ? "text-xl" : ""}`}
                  />
                  {open ? "Income: ₹ 0.00" : ""}
                </div>
              </div>
              {userID == "1" && (
                <Link
                  to={"users"}
                  className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-1 pl-2"
                >
                  <PiUsersThreeBold className="text-2xl font-light" />
                  <div className={`text-base ${open ? "px-4 py-2" : "pl-0"}`}>
                    All Users
                  </div>
                </Link>
              )}

              <div
                className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-2 ml-2"
                onClick={() => setTemplateShow(!templateShow)}
              >
                <FaSheetPlastic className="text-2xl" />
                <div className={`text-base ${open ? "ml-4" : "ml-0"}`}>
                  {open && "Templates"}
                </div>
                <div className="flex justify-end" style={{ width: "35%" }}>
                  <IoIosArrowForward
                    className={`text-xl transform duration-300 ${
                      templateShow ? "rotate-90" : "rotate-0"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col items-start ml-[20px] transition-all duration-500 overflow-hidden ${
                  templateShow && open
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <Link to={"templates-list"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaSheetPlastic className="mr-4" />
                    {open && "Templates"}
                  </div>
                </Link>
                <Link to={"footer-list"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaSheetPlastic className="mr-4" />
                    Footers
                  </div>
                </Link>
              </div>
              <div
                className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-2 ml-2"
                onClick={() => setMeetingReportShow(!meetingReportShow)}
              >
                <CiVideoOn className="text-3xl" />
                <div className={`text-base ${open ? "ml-4" : "ml-0"}`}>
                  {open && "Meetings"}
                </div>
                <div className="flex justify-end" style={{ width: "31%" }}>
                  <IoIosArrowForward
                    className={`text-xl transform duration-300 ${
                      meetingReportShow ? "rotate-90" : "rotate-0"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col items-start ml-[20px] transition-all duration-500 overflow-hidden ${
                  meetingReportShow && open
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <Link to={"google-meet-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <SiGooglemeet className="mr-4" />
                    Google Meet
                  </div>
                </Link>
                <Link to={"microsoft-team-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <BsMicrosoftTeams className="mr-4" />
                    Microsoft Team
                  </div>
                </Link>
                <Link to={"zoom-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <TbBrandZoom className="mr-4" />
                    Zoom
                  </div>
                </Link>
              </div>

              <div
                className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-2 ml-2"
                onClick={() => setReportShow(!reportShow)}
              >
                <TbReportAnalytics className="text-3xl" />
                <div className={`text-base ${open ? "ml-4" : "ml-0"}`}>
                  {open && "Reports"}
                </div>
                <div className="flex justify-end" style={{ width: "35%" }}>
                  <IoIosArrowForward
                    className={`text-xl transform duration-300 ${
                      reportShow ? "rotate-90" : "rotate-0"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col items-start ml-[20px] transition-all duration-500 overflow-hidden ${
                  reportShow && open
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <Link to={"call-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <IoIosCall className="mr-4" />
                    Call Report
                  </div>
                </Link>
                <Link to={"whatsapp-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaWhatsapp className="mr-4" />
                    Whatsapp Report
                  </div>
                </Link>
                <Link to={"sms-report"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaSms className="mr-4" />
                    SMS Report
                  </div>
                </Link>
              </div>

              {menus.map((menu, i) => (
                <Link
                  to={menu.link}
                  key={i}
                  className={`group flex items-center text-lg gap-3.5 p-3 rounded-xl ${
                    !open
                      ? "justify-center hover:bg-gray-600"
                      : "hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
                  }`}
                >
                  <div className={`${!open ? "ml-3 px-2" : "ml-0"}`}>
                    {React.createElement(menu.icon, { size: "25" })}
                  </div>
                  <h2
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`whitespace-pre duration-500 ${
                      !open && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    {menu.name}
                  </h2>
                  <h2
                    className={`${
                      open && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}
                  >
                    {menu.name}
                  </h2>
                </Link>
              ))}
            </div>
          </div>

          <div
            className={`${
              open ? "ml-64" : "ml-16"
            } duration-500 m-3 text-xl text-gray-900 font-semibold w-full`}
          >
            <div className="flex flex-row justify-between items-stretch	p-3 pt-2">
              <div className="flex flex-row items-center">
                {/* <Link to={""}> */}
                <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 items-center hover:text-gray-700">
                  <div className="mr-1">
                    <MdOutlineDashboard />
                  </div>
                  WorkPlace
                </div>
                {/* </Link> */}
                <Link to={"alldata"}>
                  <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 cursor-pointer items-center hover:text-gray-700">
                    <div className="mx-3">
                      <FaDatabase />
                    </div>
                    Data
                  </div>
                </Link>
                <Link to={"leads"}>
                  <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 cursor-pointer items-center hover:text-gray-700">
                    <div className="mx-3">
                      <SiGoogleadsense />
                    </div>
                    Leads
                  </div>
                </Link>
                <Link to={"payment"}>
                  <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 cursor-pointer items-center hover:text-gray-700">
                    <div className="mx-3">
                      <SiGoogleadsense />
                    </div>
                    Payment
                  </div>
                </Link>
                <div className="mx-5 ml-[60px] flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <FaSms />
                  </div>
                  <div className="font-light text-base">4</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <IoIosCall />
                  </div>
                  <div className="font-light text-base">0</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <MdOutlineMessage />
                  </div>
                  <div className="font-light text-base">0</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <SiWhatsapp />
                  </div>
                  <div className="font-light text-base">4</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <FaVideo />
                  </div>
                  <div className="font-light">0</div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 mx-3 cursor-pointer items-center hover:text-gray-700">
                  <PiUsersThreeBold className="text-xl" />
                  Team
                </div>
                <div
                  className="flex flex-row text-lg lg:text-base font-medium text-gray-500 mx-3 cursor-pointer items-center hover:text-gray-700"
                  onClick={logoutHandler}
                >
                  <RiLogoutBoxLine />
                  Logout
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "rgb(184 197 225)",
                width: "100%",
                height: "1px",
              }}
            ></div>
            <Routes>
              <Route path="/" element={<MainDashboad />} />
              {/* <Route path="admin" element={<Navigate to="admin" />} /> */}
              <Route path="leads" element={<AllLeads />} />
              <Route path="alldata" element={<AllData />} />
              <Route path="users" element={<Users />} />
              <Route path="credentials" element={<Credentials />} />
              {/* <Route path="admin" element={<Admin />} /> */}
              {/* <Route path="googledata" element={<GoogleData />} /> */}
              <Route path="scheduletask" element={<ScheduleTask />} />
              <Route path="validate-email" element={<ValidEmails />} />
              <Route path="track-employee" element={<TrackEmployeeMap />} />
              <Route path="add-geofence" element={<AddGeofence />} />
              <Route path="add-whatsapp" element={<AddWhatsapp />} />
              <Route path="attendence" element={<AttendenceQrCode />} />
              <Route path="profile" element={<CompleteProfile />} />
              <Route path="call-report" element={<CallReport />} />
              <Route path="whatsapp-report" element={<WhatsAppReport />} />
              <Route path="sms-report" element={<SMSReport />} />
              <Route path="google-meet-report" element={<GoogleMeetReport />} />
              <Route
                path="microsoft-team-report"
                element={<MicrosoftTeamReport />}
              />
              <Route path="zoom-report" element={<ZoomReport />} />
              <Route path="templates-list" element={<TemplatesList />} />
              <Route path="footer-list" element={<FooterList />} />
              <Route path="add-template" element={<AddTemplate />} />
              <Route path="add-footer" element={<AddFooter />} />
              <Route path="edit-template" element={<EditTemplate />} />
              <Route path="payment" element={<Payment />} />
            </Routes>
          </div>
        </>
      ) : (
        <>
          <CompleteProfile />
        </>
      )}
    </section>
  );
};
