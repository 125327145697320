import React, { useEffect, useState } from "react";
import "./App.css";
import { AllRoutes } from "./Routes/AllRoutes";
import { onMessageListener } from "./firebase/firebase";

function App() {
  useEffect(() => {
    // Check if notifications are already enabled when the component mounts
    if (Notification.permission === "denied") {
      // alert(
      //   "Notification Disabled, if we want to get notification about seen email and etc. Enable notification from site settings"
      // );
    }
  }, []);
  useEffect(() => {
    requestPermission();
    // Request permission to show notifications and get FCM token

    // Listen for messages when app is in the foreground
    onMessageListener()
      .then((payload) => {})
      .catch((err) => console.error("Failed to receive notification", err));
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // await requestFirebaseNotificationPermission()
    } else {
      // alert(
      //   "Notification Disabled, if we want to get notification about seen email and etc. Enable notification from site settings"
      // );
    }
  };

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
