import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const ForgetPasswordOtpDialog = ({ open, onClose, onSubmit }) => {
  const [otp, setOtp] = useState(Array(4).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input if value is entered
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    // Move to the previous input if backspace is pressed and the current input is empty
    if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(otp.join("")); // Join the OTP array into a single string and pass it to the parent
  };

  if (!open) return null; // Don't render if not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg w-96">
        <h2 className="text-lg font-semibold text-center">Enter OTP</h2>
        <form
          id="otp-form"
          onSubmit={handleSubmit}
          className="flex justify-center space-x-2 mt-4"
        >
          {otp.map((value, index) => (
            <input
              key={index}
              type="text"
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              onFocus={(e) => e.target.select()} // Select the input value on focus
              ref={(ref) => (inputRefs.current[index] = ref)}
              maxLength={1} // Restrict input to a single character
              className="w-12 h-12 text-center border border-gray-300 rounded focus:border-blue-500 focus:outline-none text-2xl"
            />
          ))}
        </form>
        <div className="flex justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 text-gray-800 rounded px-4 py-2 hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            type="submit"
            form="otp-form"
            className="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

// Prop types for better validation
ForgetPasswordOtpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ForgetPasswordOtpDialog;
